define("milegacy/templates/layouts/app", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 111
            },
            "end": {
              "line": 1,
              "column": 260
            }
          },
          "moduleName": "milegacy/templates/layouts/app.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "src", "/assets/images/logo.svg");
          dom.setAttribute(el1, "alt", "MiLegacy Logo");
          dom.setAttribute(el1, "class", "logo");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "name t-h2");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "page.banner", ["loc", [null, [1, 238], [1, 253]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 380
                },
                "end": {
                  "line": 1,
                  "column": 580
                }
              },
              "moduleName": "milegacy/templates/layouts/app.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-down icon-bottom icon-gray");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-calendar icon-timecapsule icon-outline");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "name t-h2");
              var el2 = dom.createTextNode("Timecapsule");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 580
                },
                "end": {
                  "line": 1,
                  "column": 810
                }
              },
              "moduleName": "milegacy/templates/layouts/app.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "name t-h2");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["icon icon-down icon-bottom icon-", ["get", "page.menu.iconColor", ["loc", [null, [1, 632], [1, 651]]]]]]], ["attribute", "class", ["concat", ["icon icon-", ["get", "page.menu.iconColor", ["loc", [null, [1, 681], [1, 700]]]], " icon-", ["get", "page.menu.iconStyle", ["loc", [null, [1, 710], [1, 729]]]], " icon-", ["get", "page.menu.icon", ["loc", [null, [1, 739], [1, 753]]]]]]], ["content", "page.menu.text", ["loc", [null, [1, 785], [1, 803]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 332
              },
              "end": {
                "line": 1,
                "column": 817
              }
            },
            "moduleName": "milegacy/templates/layouts/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "page.menu.text", ["loc", [null, [1, 390], [1, 404]]]], "Timecapsules"], [], ["loc", [null, [1, 386], [1, 420]]]]], [], 0, 1, ["loc", [null, [1, 380], [1, 817]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 278
            },
            "end": {
              "line": 1,
              "column": 840
            }
          },
          "moduleName": "milegacy/templates/layouts/app.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("nav");
          dom.setAttribute(el1, "class", "item menu u-show-medium");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "menuModal", ["loc", [null, [1, 354], [1, 363]]]]], [], []], "class", "spacey"], 0, null, ["loc", [null, [1, 332], [1, 834]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 918
              },
              "end": {
                "line": 1,
                "column": 997
              }
            },
            "moduleName": "milegacy/templates/layouts/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-notifications");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1009
              },
              "end": {
                "line": 1,
                "column": 1083
              }
            },
            "moduleName": "milegacy/templates/layouts/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-network");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1102
              },
              "end": {
                "line": 1,
                "column": 1188
              }
            },
            "moduleName": "milegacy/templates/layouts/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-me");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 893
            },
            "end": {
              "line": 1,
              "column": 1200
            }
          },
          "moduleName": "milegacy/templates/layouts/app.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["notifications"], ["class", "tab"], 0, null, ["loc", [null, [1, 918], [1, 1009]]]], ["block", "link-to-friend", ["friends"], ["class", "tab"], 1, null, ["loc", [null, [1, 1009], [1, 1102]]]], ["block", "link-to", ["milestones", ["get", "model.currentUser.id", ["loc", [null, [1, 1126], [1, 1146]]]]], ["class", "tab"], 2, null, ["loc", [null, [1, 1102], [1, 1200]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1234
              },
              "end": {
                "line": 1,
                "column": 1370
              }
            },
            "moduleName": "milegacy/templates/layouts/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "/assets/images/small-logo.svg");
            dom.setAttribute(el1, "alt", "MiLegacy Small Logo");
            dom.setAttribute(el1, "class", "logo");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Sign Up");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1200
            },
            "end": {
              "line": 1,
              "column": 1388
            }
          },
          "moduleName": "milegacy/templates/layouts/app.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("nav");
          dom.setAttribute(el1, "class", "item sign-up");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "link-to", ["login"], ["class", "button"], 0, null, ["loc", [null, [1, 1234], [1, 1382]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1436
          }
        },
        "moduleName": "milegacy/templates/layouts/app.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("header");
        dom.setAttribute(el1, "class", "navbar");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navbar-content row");
        var el3 = dom.createElement("nav");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [0]);
        var element4 = dom.childAt(element3, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 0, 0);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 2, 2);
        morphs[4] = dom.createMorphAt(element3, 3, 3);
        morphs[5] = dom.createMorphAt(element2, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["item home ", ["subexpr", "if", [["get", "page.menu", ["loc", [null, [1, 82], [1, 91]]]], "u-hide-medium"], [], ["loc", [null, [1, 77], [1, 109]]]]]]], ["block", "link-to", ["index"], ["class", "spacey"], 0, null, ["loc", [null, [1, 111], [1, 272]]]], ["block", "if", [["get", "page.menu", ["loc", [null, [1, 284], [1, 293]]]]], [], 1, null, ["loc", [null, [1, 278], [1, 847]]]], ["inline", "search-widget", [], ["modal", ["subexpr", "@mut", [["get", "menuModal", ["loc", [null, [1, 869], [1, 878]]]]], [], []], "class", "item"], ["loc", [null, [1, 847], [1, 893]]]], ["block", "if", [["get", "model.currentUser", ["loc", [null, [1, 899], [1, 916]]]]], [], 2, 3, ["loc", [null, [1, 893], [1, 1395]]]], ["inline", "partial", ["shared/toast"], [], ["loc", [null, [1, 1401], [1, 1427]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});