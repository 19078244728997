define('milegacy/routes/account/settings', ['exports', 'milegacy/routes/base'], function (exports, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:layout': 'modal',
    devise: Ember.inject.service('devise'),

    actions: {
      logout: function logout() {
        var devise = this.get('devise');
        var session = this.get('session');
        var token = session.get('data.authenticated.token');
        var user_id = session.get('data.authenticated.id');
        devise.sendLogoutRequest(token, user_id).then(function () {
          session.invalidate();
        });
      }
    }
  });
});