define('milegacy/serializers/story', ['exports', 'milegacy/serializers/application'], function (exports, _milegacySerializersApplication) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _milegacySerializersApplication['default'].extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var changed_milestone_id = json.story.changed_milestone && json.story.changed_milestone.id;
      var milestone_id = json.story.milestone_id;

      if (changed_milestone_id) {
        if (changed_milestone_id != milestone_id) {
          json.story.milestone_id = changed_milestone_id;
        }
      }

      return _defineProperty({}, snapshot.modelName, json.story);
    }
  });
});