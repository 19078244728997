define('milegacy/services/ajax', ['exports', 'ember', 'milegacy/utils/transition-lock'], function (exports, _ember, _milegacyUtilsTransitionLock) {
  exports['default'] = _ember['default'].Service.extend({
    count: 0,
    track: function track(promise) {
      var _this = this;

      this.increment();
      promise['finally'](function () {
        _this.decrement();
      });
    },
    increment: function increment() {
      this.set('count', this.get('count') + 1);
    },
    decrement: function decrement() {
      this.set('count', this.get('count') - 1);
    },
    loading: (function () {
      return this.get('count') > 0;
    }).property('count'),
    i18n: _ember['default'].inject.service(),
    setupLock: (function () {
      var _this2 = this;

      var i18n = this.get('i18n');
      this.unlock = (0, _milegacyUtilsTransitionLock.lockWindow)(function (ask) {
        if (_this2.get('loading')) {
          ask(i18n.t('transition.ajax'));
        }
      });
    }).on('init'),
    willDestroy: function willDestroy() {
      this.unlock();
    }
  });
});