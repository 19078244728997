define('milegacy/models/story', ['exports', 'ember', 'ember-data', 'milegacy/models/commentable', 'milegacy/utils/computed'], function (exports, _ember, _emberData, _milegacyModelsCommentable, _milegacyUtilsComputed) {
  exports['default'] = _milegacyModelsCommentable['default'].extend({
    title: _emberData['default'].attr('string'),
    body: _emberData['default'].attr('string'),
    audience: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    milestone: _emberData['default'].belongsTo('milestone'),
    milestoneId: _ember['default'].computed.readOnly('milestone.id'),
    user: _emberData['default'].belongsTo('user'),
    userId: _ember['default'].computed.readOnly('user.id'),
    friendId: _emberData['default'].attr('string'),
    comments: _emberData['default'].hasMany('comment'),
    media: _emberData['default'].hasMany('media'),
    isBlank: (0, _milegacyUtilsComputed.isBlank)('title', 'body', 'media'),
    publish_date: _emberData['default'].attr('string'),
    event_date: _emberData['default'].attr('string'),
    isAddedAsTimeCapsuleStory: _ember['default'].computed.bool('publish_date'),
    isTimeCapsuleStory: (function () {
      if (this.get('publish_date') != null) {
        var publishDate = new Date(this.get('publish_date'));
        var currentDate = new Date();
        return publishDate.getTime() > currentDate.getTime();
      } else {
        return false;
      }
    }).property('createdAt', 'publish_date').readOnly(),
    changedMilestone: _emberData['default'].attr()
  });
});