define('milegacy/persistors/story', ['exports', 'ember', 'npm:lodash/array/difference'], function (exports, _ember, _npmLodashArrayDifference) {

  var Promise = _ember['default'].RSVP.Promise;

  exports['default'] = _ember['default'].Object.extend({
    isSaving: false,
    story: null,
    save: function save() {
      var story;
      return regeneratorRuntime.async(function save$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('isSaving', true);

            story = this.get('story');
            context$1$0.prev = 2;
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(story.save());

          case 5:
            context$1$0.next = 10;
            break;

          case 7:
            context$1$0.prev = 7;
            context$1$0.t0 = context$1$0['catch'](2);
            throw context$1$0.t0;

          case 10:
            context$1$0.prev = 10;

            this.set('isSaving', false);
            return context$1$0.finish(10);

          case 13:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this, [[2, 7, 10, 13]]);
    },
    saveMedia: function saveMedia() {
      var media, promises;
      return regeneratorRuntime.async(function saveMedia$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            media = this.get('story.media');
            promises = media.map(function callee$1$0(m) {
              var promise;
              return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    if (!(m.get('status') !== 'created')) {
                      context$2$0.next = 2;
                      break;
                    }

                    return context$2$0.abrupt('return');

                  case 2:
                    context$2$0.next = 4;
                    return regeneratorRuntime.awrap(m.save());

                  case 4:
                    context$2$0.prev = 4;
                    promise = m.get('file').upload();

                    this.get('ajax').track(promise);
                    context$2$0.next = 9;
                    return regeneratorRuntime.awrap(promise);

                  case 9:
                    m.set('status', 'uploaded');
                    context$2$0.next = 16;
                    break;

                  case 12:
                    context$2$0.prev = 12;
                    context$2$0.t0 = context$2$0['catch'](4);

                    // eslint-disable-next-line no-console
                    console.error(context$2$0.t0);
                    m.set('status', 'failed');

                  case 16:
                    context$2$0.prev = 16;
                    context$2$0.next = 19;
                    return regeneratorRuntime.awrap(m.save());

                  case 19:
                    return context$2$0.finish(16);

                  case 20:
                  case 'end':
                    return context$2$0.stop();
                }
              }, null, _this, [[4, 12, 16, 20]]);
            });
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(Promise.all(promises));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    deleteOrphanedMedia: function deleteOrphanedMedia() {
      var media, original, current, orphaned;
      return regeneratorRuntime.async(function deleteOrphanedMedia$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            media = this.get('story.media.content');
            original = media.canonicalState;
            current = media.currentState;
            orphaned = (0, _npmLodashArrayDifference['default'])(original, current);
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(Promise.all(orphaned.map(function (item) {
              return item.record.destroyRecord();
            })));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});

// Save + upload files concurrently