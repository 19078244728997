define('milegacy/routes/reset', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'Authenticate',
    'page:banner': 'MiLegacy User Authentication',

    session: _ember['default'].inject.service('session'),

    model: function model() {
      return this.get('session.currentUser');
    },

    actions: {
      resetFlag: function resetFlag(model) {
        var _this = this;

        var adapter = this.get('store').adapterFor('user');
        var url = "/users/" + model.id + "/set_acceptance_flag";

        return adapter.ajax(adapter.host + url, 'PATCH', { "acceptance_flag": false }).then(function () {
          _this.get('session').invalidate();
        });
      }
    }
  });
});
/**
 * Created by subratthakur on 1/9/18.
 */