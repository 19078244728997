define('milegacy/routes/login', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy', 'milegacy/config/environment'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy, _milegacyConfigEnvironment) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    'page:title': 'MiLegacy',
    'page:banner': 'MiLegacy',
    credentials: _ember['default'].inject.service('credentials'),
    session: _ember['default'].inject.service('session'),
    devise: _ember['default'].inject.service('devise'),
    wpLinks: _milegacyConfigEnvironment['default'].wpLinks,
    user: '',

    beforeModel: function beforeModel() {
      if (window.location.pathname == '/') {
        var login_identifier = document.getElementById("root");
        login_identifier.className += " login-page-wrap";
      } else {
        // do nothing
        setTimeout(function () {
          if (window.location.href.indexOf("login") > -1) {
            var login_identifier = document.getElementById("root");
            login_identifier.className += " login-page-wrap";
          }
        }, 10);
        if (window.location.href.indexOf("wp/login") > -1) {
          var login_identifier = document.getElementById("root");
          login_identifier.className += " wp-login-page-wrap";
        }
      }
    },

    model: function model(params, transition) {
      var _get$getProperties = this.get('credentials').getProperties('email', 'password');

      var email = _get$getProperties.email;
      var password = _get$getProperties.password;

      var firstName = "";
      var lastName = "";
      var dob = "";
      var gender = "";
      var confirmedAccount = true;

      if (_ember['default'].isPresent(transition.queryParams.email)) {
        email = transition.queryParams.email.replace(' ', '+');
      }

      return _ember['default'].Object.create({
        email: email, password: password, firstName: firstName, lastName: lastName, dob: dob, gender: gender, confirmedAccount: confirmedAccount,
        operation: this.createOperation()
      });
    },

    actions: {
      authenticate: function authenticate(model) {
        var _this = this;

        var session = this.get('session');
        var isAuthenticated = session.get('isAuthenticated');

        var promise = session.authenticate('authenticator:application', model.email, model.password)['catch'](function (data) {
          if (data.error == 'You have to confirm your email address before continuing.') {
            model.set('confirmedAccount', false);
          } else {
            model.set('confirmedAccount', true);
          }

          model.set('error', data.error);
        }).then(function () {
          session.set('loaded', false);
          if (isAuthenticated) {
            _this.transitionTo('index');
          }
        });

        this.updateOperation(promise);
      },

      sendConfirmationEmail: function sendConfirmationEmail(model) {
        var devise = this.get('devise');

        devise.sendConfirmationEmail(model.email);
        $('label.error').text('Successfully sent email.');
      },

      hamburgerClick: function hamburgerClick() {
        var isClassActive = $('.nav-header div').hasClass('is-active');
        if (isClassActive) {
          $('.nav-header div').removeClass("is-active");
          document.getElementById('menu-header-right-menu').style.display = "none";
        } else {
          $('.nav-header div').addClass("is-active");
          document.getElementById('menu-header-right-menu').style.display = "block";
        }
      },
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        this.get('credentials').setProperties(model.getProperties('email', 'password'));

        return true;
      }
    },

    validUserDetail: function validUserDetail(model) {
      return model.get('firstName').trim().length > 0 && model.get('email').trim().length > 0 && model.get('password').trim().length > 0 && model.get('dob').toString().trim().length > 0 && model.get('gender').trim().length > 0;
    }
  });
});