define('milegacy/routes/users/new', ['exports', 'ember', 'milegacy/routes/public-base', 'npm:moment'], function (exports, _ember, _milegacyRoutesPublicBase, _npmMoment) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    'page:title': 'Sign Up',
    'page:banner': 'Sign Up',
    credentials: _ember['default'].inject.service('credentials'),
    model: function model() {
      var credentials = this.get('credentials').getProperties('email', 'password');

      return _ember['default'].Object.create({
        user: this.store.createRecord('user', credentials),
        datePickerOptions: {
          max: (0, _npmMoment['default'])().subtract(_npmMoment['default'].duration(13, 'year')).toDate(),
          selectYears: 100,
          selectMonths: true,
          format: 'mm-dd-yyyy',
          formatSubmit: 'yyyy-mm-dd'
        }
      });
      // return this.store.createRecord('user', credentials);
    },
    actions: {
      submit: function submit(model) {
        var _this = this;

        model.user.set('acceptance_flag', true);
        return model.user.save().then(function () {
          return _this.transitionTo('users.success');
        });
      },
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        this.get('credentials').setProperties(model.user.getProperties('email', 'password'));
        model.user.rollbackAttributes();
      }
    }
  });

  if (window.location.href.indexOf("new") > -1) {
    var new_account_identifier = document.getElementById("root");
    new_account_identifier.className += " new-account-page-wrap";
  }
});