define("milegacy/templates/authenticate", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 274
              },
              "end": {
                "line": 1,
                "column": 394
              }
            },
            "moduleName": "milegacy/templates/authenticate.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "#black-studio-tinymce-2");
            dom.setAttribute(el1, "class", "dob-msg");
            var el2 = dom.createTextNode("Why do I need to provide my birthday?");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 719
                },
                "end": {
                  "line": 1,
                  "column": 781
                }
              },
              "moduleName": "milegacy/templates/authenticate.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "type", "submit");
              var el2 = dom.createTextNode("OK");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 411
              },
              "end": {
                "line": 1,
                "column": 798
              }
            },
            "moduleName": "milegacy/templates/authenticate.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h3");
            dom.setAttribute(el1, "class", "timecapsules-header");
            var el2 = dom.createTextNode("Age Restriction");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "sub-header");
            var el2 = dom.createTextNode("Your Birthday defines whether our utility can be used.  For the protection of those under the age of 13 requirements have been put in place, that we at Milegacy fully support.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 741], [1, 746]]]]], [], []]], 0, null, ["loc", [null, [1, 719], [1, 798]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 245
            },
            "end": {
              "line": 1,
              "column": 809
            }
          },
          "moduleName": "milegacy/templates/authenticate.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 296], [1, 301]]]]], [], []]], 0, null, ["loc", [null, [1, 274], [1, 411]]]], ["block", "pop-up", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 427], [1, 432]]]]], [], []], "class", "edit min-age-factor"], 1, null, ["loc", [null, [1, 411], [1, 809]]]]],
        locals: ["modal"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1830
          }
        },
        "moduleName": "milegacy/templates/authenticate.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "authenticate-main-wrapper");
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "authenticate-wrapper");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "control left-side");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "radio-group gender-wrap");
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "radio-group-label");
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Female");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "radio-group-label");
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Male");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "t-c-wrap-auth");
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "checkbox");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("By Clicking Continue, you agree to");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://wp.milegacy.com/terms-and-conditions/");
        dom.setAttribute(el4, "rel", "noopener");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createTextNode(" our Terms");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode(" and that you have read and agree to our");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://wp.milegacy.com/privacy-policy/");
        dom.setAttribute(el4, "rel", "noopener");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createTextNode(" Privacy Policy");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "submit-btn-wrap-auth");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "field button submit");
        var el5 = dom.createTextNode("Cancel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "field button submit");
        var el5 = dom.createTextNode("Continue");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 0]);
        var element1 = dom.childAt(element0, [2]);
        var element2 = dom.childAt(element0, [3, 0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element2, [], true);
        }
        var element3 = dom.childAt(element0, [4]);
        var element4 = dom.childAt(element3, [0]);
        var element5 = dom.childAt(element3, [1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [0]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'checked');
        morphs[5] = dom.createAttrMorph(element2, 'value');
        morphs[6] = dom.createElementMorph(element2);
        morphs[7] = dom.createElementMorph(element4);
        morphs[8] = dom.createAttrMorph(element5, 'disabled');
        morphs[9] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [["inline", "date-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 92], [1, 97]]]]], [], []], "name", "dob", "value", ["subexpr", "@mut", [["get", "model.dob", ["loc", [null, [1, 115], [1, 124]]]]], [], []], "placeholder", "Choose your Birth Date", "options", ["subexpr", "@mut", [["get", "datePickerOptions", ["loc", [null, [1, 170], [1, 187]]]]], [], []], "class", "date-timecapsule"], ["loc", [null, [1, 73], [1, 214]]]], ["block", "modal-context", [], [], 0, null, ["loc", [null, [1, 245], [1, 827]]]], ["inline", "radio-button", [], ["name", "gender", "groupValue", ["subexpr", "@mut", [["get", "model.gender", ["loc", [null, [1, 964], [1, 976]]]]], [], []], "value", "male"], ["loc", [null, [1, 924], [1, 991]]]], ["inline", "radio-button", [], ["name", "gender", "groupValue", ["subexpr", "@mut", [["get", "model.gender", ["loc", [null, [1, 1091], [1, 1103]]]]], [], []], "value", "female"], ["loc", [null, [1, 1051], [1, 1120]]]], ["attribute", "checked", ["get", "auth_status", ["loc", [null, [1, 1234], [1, 1245]]]]], ["attribute", "value", ["get", "auth_status", ["loc", [null, [1, 1256], [1, 1267]]]]], ["element", "action", ["handleCheckboxChange", ["get", "event", ["loc", [null, [1, 1200], [1, 1205]]]]], [], ["loc", [null, [1, 1168], [1, 1207]]]], ["element", "action", ["cancelAndLogout"], [], ["loc", [null, [1, 1619], [1, 1647]]]], ["attribute", "disabled", ["subexpr", "not", [["get", "enableContinue", ["loc", [null, [1, 1750], [1, 1764]]]]], [], ["loc", [null, [1, 1744], [1, 1766]]]]], ["element", "action", ["loginAndContinue", ["get", "model", ["loc", [null, [1, 1727], [1, 1732]]]]], [], ["loc", [null, [1, 1699], [1, 1734]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});