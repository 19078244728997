define('milegacy/router', ['exports', 'ember', 'milegacy/config/environment'], function (exports, _ember, _milegacyConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _milegacyConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('wp', function () {
      this.route('login');
    });
    this.route('account', function () {
      this.route('settings', function () {
        this.route('credentials');
        this.route('deactivate');
      });
      this.route('confirm', function () {
        this.route('error');
      });
      this.route('password', function () {
        this.route('request');
        this.route('sent');
        this.route('reset');
      });
    });

    this.route('users', function () {
      this.route('new');
      this.route('success');
      this.route('user', { resetNamespace: true, path: ':user_id' }, function () {
        this.route('edit');
        this.route('milestones', { resetNamespace: true }, function () {
          this.route('milestone', { resetNamespace: true, path: ':milestone_id' }, function () {
            this.route('stories', { resetNamespace: true }, function () {
              this.route('story', { resetNamespace: true, path: ':story_id' }, function () {});
            });
          });
        });
      });
    });

    this.route('friends', function () {
      this.route('friend', { resetNamespace: true, path: ':status' }, function () {});
      this.route('suggestions');
    });

    this.route('notifications', function () {
      this.route('notification', { resetNamespace: true, path: ':milestone_id' }, function () {});
    });
    this.route('authenticate');
    this.route('reset');
  });

  exports['default'] = Router;
});