define('milegacy/utils/countable', ['exports', 'ember', 'milegacy/utils/live-filter', 'milegacy/utils/parse-order'], function (exports, _ember, _milegacyUtilsLiveFilter, _milegacyUtilsParseOrder) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports.countable = countable;

  var Countable = _ember['default'].ArrayProxy.extend({
    content: [],
    // (page, per) => store.query(...)
    fetch: _ember['default'].K,
    // Grab the first page on init?
    prefetch: true,
    // Determine page number based on store contents?
    cache: false,
    // Currently fetching a page?
    inProgress: false,
    // Results per page
    perPage: 5,
    // Last fetched page
    prevPage: 0,
    // Last page number
    lastPage: null,
    // Loaded the last page?
    loadedAll: false,
    // Keypath to grab # of pages
    totalPagesKeypath: 'meta.pagination.total_pages',

    onInit: (function () {
      if (this.get('prefetch')) {
        this.fetchNext();
      }
    }).on('init'),
    fetchNext: function fetchNext() {
      var _this = this;

      var loading = this.get('inProgress');
      var loadedAll = this.get('loadedAll');
      if (loading || loadedAll) {
        return;
      }

      var len = this.get('length');
      var perPage = this.get('perPage');
      var prevPage = this.get('prevPage');
      var nextPage = prevPage + 1;

      if (this.get('cache')) {
        nextPage = Math.floor(len / perPage) + 1;
      }

      var fetch = this.get('fetch');
      var promise = fetch.call(this, nextPage, perPage);

      this.set('inProgress', true);

      promise.then(function () {
        var lastPage = promise.get('content.' + _this.get('totalPagesKeypath'));
        _this.set('lastPage', lastPage);
        _this.set('inProgress', false);
        _this.set('prevPage', nextPage);
        if (_ember['default'].isPresent(lastPage) && nextPage >= lastPage) {
          _this.set('loadedAll', true);
        }
      });
    }
  });

  exports['default'] = Countable;

  function countable(store, modelName, keys, query, filterWith, sort, opts) {
    var sortString;
    // var allData;

    if (_ember['default'].isPresent(sort)) {
      sortString = sort.map(function (order) {
        var _parseOrder = (0, _milegacyUtilsParseOrder['default'])(order);

        var desc = _parseOrder.desc;
        var key = _parseOrder.key;

        return (desc ? '-' : '') + key.underscore();
      }).join(',');
    }

    var fetch = function fetch(page, per) {
      var defaults = { page: page, per: per };

      if (_ember['default'].isPresent(sortString)) {
        defaults.sort = sortString;
      }

      return store.query(modelName, _ember['default'].merge(defaults, query));
    };

    // if(modelName == "timecapsule"){
    //   allData = store.peekAll('story').sortBy('createdAt');
    // } else if(query.filter && query.filter.milestone_id && modelName != "notification") {
    //   allData = store.peekAll(modelName);
    // } else{
    //   allData = store.peekAll(modelName);
    // }

    var content = _milegacyUtilsLiveFilter['default'].create({
      source: store.peekAll(modelName), filterWith: filterWith, keys: keys, sort: sort });

    return Countable.create(_extends({ fetch: fetch, content: content }, opts));
  }
});