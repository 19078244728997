define('milegacy/components/date-field', ['exports', 'ember', 'milegacy/components/text-field', 'npm:moment'], function (exports, _ember, _milegacyComponentsTextField, _npmMoment) {

  var nativeDatepicker = function nativeDatepicker($el) {
    return $el.is('[type="date"]') && $el.prop('type') === 'date';
  };

  var isTouch = function isTouch() {
    return 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch;
  };

  exports['default'] = _milegacyComponentsTextField['default'].extend({
    type: 'text',
    layoutName: 'components/text-field',
    options: {},
    transformedValue: _ember['default'].computed('value', {
      get: function get() {
        var value = this.get('value');
        if (value) {
          return (0, _npmMoment['default'])(value).format('MM-DD-YYYY');
        }
      },
      set: function set(_, value) {
        this.set('value', _ember['default'].isPresent(value) ? (0, _npmMoment['default'])(value, 'MM-DD-YYYY') : null);
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      var $input = this.$('input');

      if (!nativeDatepicker($input) || !isTouch()) {
        $input.pickadate(this.get('options'));
        this.picker = $input.pickadate('picker');
      }
    },
    willDestroyElement: function willDestroyElement() {
      var picker = this.get('picker');
      if (picker && picker.get('start')) {
        picker.stop();
      }
    }
  });
});