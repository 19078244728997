define('milegacy/routes/notifications', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'MiNews',
    'page:banner': 'MiNews',
    'page:layout': 'app',
    'page:menu': _ember['default'].computed.alias('menu'),
    beforeModel: function beforeModel() {
      var store = this.get('store');
      return Promise.all([store.queryRecord('notification-digest', {
        filter: {
          group_by: 'milestone',
          read: false,
          muted: false
        }
      }), store.queryRecord('timecapsule-digest', {
        filter: {
          group_by: 'milestone',
          user_id: this.get('session.currentUser.id')
        }
      })]);
    },
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'All News',
        icon: 'all',
        iconColor: 'gray'
      }));

      return _ember['default'].Object.create({
        prompt: _ember['default'].Object.create({
          link: 'notifications.index',
          name: 'All News'
        })
      });
    }
  });
});