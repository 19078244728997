define('milegacy/app', ['exports', 'ember', 'ember-resolver', 'ember-load-initializers', 'milegacy/config/environment', 'milegacy/inflector'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _milegacyConfigEnvironment, _milegacyInflector) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _milegacyConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _milegacyConfigEnvironment['default'].podModulePrefix,
    rootElement: '#root',
    Resolver: _emberResolver['default']
  });

  (0, _milegacyInflector['default'])();
  (0, _emberLoadInitializers['default'])(App, _milegacyConfigEnvironment['default'].modulePrefix);
  (0, _milegacyInflector['default'])();
  initGTM();
  initGA();

  /**
   * [initGA - intialize google analytics]
   * @param {[type]} gaId [tracking ID]
   */
  function initGA() {
    // google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', _milegacyConfigEnvironment['default'].gaId);

    var ga = document.createElement('script'),
        s = undefined;
    ga.type = 'text/javascript';
    ga.async = true;
    ga.src = _milegacyConfigEnvironment['default'].gaLink;
    s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);
  };

  /**
   * [initGTM - Initializes google tag manager]
   * @param {string} gtmId [tag manager container ID]
   */
  function initGTM() {
    // GTM
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', _milegacyConfigEnvironment['default'].gtmId);
  }

  exports['default'] = App;
});