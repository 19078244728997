define("milegacy/locales/en/translations", ["exports"], function (exports) {
  exports["default"] = {
    "flash": {
      "account": {
        "confirm": {
          "success": "Confirmed your email address! Go ahead and login now.",
          "error": "Your email is already confirmed."
        },
        "password": {
          "reset": {
            "success": "Your password is reset! Go ahead and login now."
          },
          "update": {
            "success": "Your password is updated!"
          }
        },
        "email": {
          "update": {
            "success": "Check your email to confirm your new email address."
          }
        },
        "deactivate": {
          "success": "Your account is gone, gone, gone."
        }
      },
      "story": {
        "notFound": "Hmm, that story doesn't seem to exist anymore."
      }
    },
    "login": {
      "link": "Log In",
      "banner": "Celebrate your life while you live it.",
      "fail": "Your email address or password is incorrect.",
      "submit": "Log In",
      "forgot-password": "Forgot Password?"
    },
    "signup": {
      "link": "or Sign Up",
      "banner": "Celebrate your life while you live it.",
      "fail": "Your email address is already in use.",
      "submit": "Sign Up"
    },
    "notification": {
      "activity": "Latest Activity:",
      "new_story": {
        "false": {
          "one": "{{count}} Unread {{tc}} Story",
          "other": "{{count}} Unread {{tc}} Stories"
        },
        "true": {
          "one": "{{count}} {{tc}} Story",
          "other": "{{count}} {{tc}} Stories"
        }
      },
      "follow_story": {
        "false": {
          "one": "{{count}} Followed {{tc}} Story",
          "other": "{{count}} Followed {{tc}} Stories"
        },
        "true": {
          "one": "{{count}} Followed {{tc}} Story",
          "other": "{{count}} Followed {{tc}} Stories"
        }
      },
      "story_activity": {
        "false": {
          "one": "{{count}} {{tc}} Story with Unread Comments",
          "other": "{{count}} {{tc}} Stories with Unread Comments"
        },
        "true": {
          "one": "{{count}} {{tc}} Story with Comments",
          "other": "{{count}} {{tc}} Stories with Comments"
        }
      },
      "empty_states": {
        "all": "No Stories",
        "unread": "No Unread Stories or Comments",
        "story_activity": "No Unread Comments",
        "new_story": "No Unread Stories",
        "follow_story": "No Followed Stories"
      },
      "friend": {
        "unread": "Friend Request"
      },
      "prompts": {
        "delete-group-notifications": "Are you sure you want to delete {{name}}'s notifications?"
      }
    },
    "friend": {
      "unfriend": "Do you want to remove {{name}} as a {{relationship}}?",
      "add_friend": "Do you want to add {{name}} as {{relationship}}?"
    },
    "story": {
      "destroy": 'Are you sure you want to delete the story, "{{name}}"?'
    },
    "comment": {
      "destroy": 'Are you sure you want to delete this comment, "{{name}}"?'
    },
    "user": {
      "profile": {
        "age": {
          "one": "{{count}} year old",
          "other": "{{count}} years old"
        }
      }
    },
    "form": {
      "user": {
        "passwordAuthorization": "Password"
      }
    },
    "transition": {
      "ajax": "Some changes are still in progress, are you sure you want to leave this page?",
      "uploader": "Looks like you were working on a story. Are you sure want to leave this page? You will lose your changes."
    }
  };
});