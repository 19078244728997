define('milegacy/routes/milestones', ['exports', 'ember', 'milegacy/routes/public-base'], function (exports, _ember, _milegacyRoutesPublicBase) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    'page:title': 'MiLegacy',
    'page:banner': 'MiLegacy',
    'page:layout': 'app',
    beforeModel: function beforeModel(transition) {
      var store = this.get('store');
      this._super(transition);
      return Promise.all([store.queryRecord('timecapsule-digest', {
        filter: {
          group_by: 'milestone',
          user_id: this.modelFor('user').get('userId')
        }
      }), store.queryRecord('story-digest', {
        filter: {
          group_by: 'milestone',
          user_id: this.modelFor('user').get('userId')
        }
      })]);
    },
    model: function model() {
      var userModel = this.modelFor('user');
      var userId = userModel.get('userId');

      return _ember['default'].Object.create({
        userId: userId,
        prompt: _ember['default'].Object.create({
          link: 'milestones.index',
          name: 'All Stories'
        })
      });
    }
  });
});