define("milegacy/templates/components/comment-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 421
                  },
                  "end": {
                    "line": 1,
                    "column": 533
                  }
                },
                "moduleName": "milegacy/templates/components/comment-list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "comment-widget", [], ["model", ["subexpr", "@mut", [["get", "comment", ["loc", [null, [1, 475], [1, 482]]]]], [], []], "unread", ["subexpr", "gt", [["get", "comment.createdAt", ["loc", [null, [1, 494], [1, 511]]]], ["get", "readAt", ["loc", [null, [1, 512], [1, 518]]]]], [], ["loc", [null, [1, 490], [1, 519]]]], "story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 526], [1, 531]]]]], [], []]], ["loc", [null, [1, 452], [1, 533]]]]],
              locals: ["comment"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 191
                },
                "end": {
                  "line": 1,
                  "column": 554
                }
              },
              "moduleName": "milegacy/templates/components/comment-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "comment-group");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "comment-list");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element1, 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "media-image", [], ["media", ["subexpr", "@mut", [["get", "commentable", ["loc", [null, [1, 281], [1, 292]]]]], [], []], "imageClicked", ["subexpr", "action", [["get", "selectMedia", ["loc", [null, [1, 314], [1, 325]]]], ["get", "commentable", ["loc", [null, [1, 326], [1, 337]]]]], [], ["loc", [null, [1, 306], [1, 338]]]], "small", true, "class", "media-thumbnail clickable-thumbnail"], ["loc", [null, [1, 261], [1, 395]]]], ["block", "each", [["get", "comments", ["loc", [null, [1, 429], [1, 437]]]]], [], 0, null, ["loc", [null, [1, 421], [1, 542]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 588
                  },
                  "end": {
                    "line": 1,
                    "column": 700
                  }
                },
                "moduleName": "milegacy/templates/components/comment-list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "comment-widget", [], ["model", ["subexpr", "@mut", [["get", "comment", ["loc", [null, [1, 642], [1, 649]]]]], [], []], "unread", ["subexpr", "gt", [["get", "comment.createdAt", ["loc", [null, [1, 661], [1, 678]]]], ["get", "readAt", ["loc", [null, [1, 679], [1, 685]]]]], [], ["loc", [null, [1, 657], [1, 686]]]], "story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 693], [1, 698]]]]], [], []]], ["loc", [null, [1, 619], [1, 700]]]]],
              locals: ["comment"],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 554
                },
                "end": {
                  "line": 1,
                  "column": 715
                }
              },
              "moduleName": "milegacy/templates/components/comment-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "comment-list");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["block", "each", [["get", "comments", ["loc", [null, [1, 596], [1, 604]]]]], [], 0, null, ["loc", [null, [1, 588], [1, 709]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 91
              },
              "end": {
                "line": 1,
                "column": 722
              }
            },
            "moduleName": "milegacy/templates/components/comment-list.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-model-type", [["get", "commentable", ["loc", [null, [1, 212], [1, 223]]]], "media"], [], ["loc", [null, [1, 197], [1, 232]]]]], [], 0, 1, ["loc", [null, [1, 191], [1, 722]]]]],
          locals: ["commentable", "comments"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 838
                },
                "end": {
                  "line": 1,
                  "column": 875
                }
              },
              "moduleName": "milegacy/templates/components/comment-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("View Older Comments");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 875
                },
                "end": {
                  "line": 1,
                  "column": 902
                }
              },
              "moduleName": "milegacy/templates/components/comment-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Hide Older Comments");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 735
              },
              "end": {
                "line": 1,
                "column": 950
              }
            },
            "moduleName": "milegacy/templates/components/comment-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "#");
            dom.setAttribute(el1, "class", "view-more");
            var el2 = dom.createElement("hr");
            dom.setAttribute(el2, "class", "line");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "prompt");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-more");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", [["get", "toggle", ["loc", [null, [1, 764], [1, 770]]]]], [], ["loc", [null, [1, 755], [1, 772]]]], ["block", "if", [["get", "isAbridged", ["loc", [null, [1, 844], [1, 854]]]]], [], 0, 1, ["loc", [null, [1, 838], [1, 909]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 958
                },
                "end": {
                  "line": 1,
                  "column": 978
                }
              },
              "moduleName": "milegacy/templates/components/comment-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 950
              },
              "end": {
                "line": 1,
                "column": 985
              }
            },
            "moduleName": "milegacy/templates/components/comment-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "comments", ["loc", [null, [1, 964], [1, 972]]]]], [], 0, null, ["loc", [null, [1, 958], [1, 985]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 992
            }
          },
          "moduleName": "milegacy/templates/components/comment-list.hbs"
        },
        isEmpty: false,
        arity: 4,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "group-by", [], ["group", "commentable.id", "key", "commentable", "collection", ["subexpr", "@mut", [["get", "comments", ["loc", [null, [1, 155], [1, 163]]]]], [], []]], 0, null, ["loc", [null, [1, 91], [1, 735]]]], ["block", "if", [["get", "overLimit", ["loc", [null, [1, 741], [1, 750]]]]], [], 1, 2, ["loc", [null, [1, 735], [1, 992]]]]],
        locals: ["comments", "toggle", "isAbridged", "overLimit"],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1009
          }
        },
        "moduleName": "milegacy/templates/components/comment-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "abridge-list", [], ["expanded", ["subexpr", "@mut", [["get", "expanded", ["loc", [null, [1, 25], [1, 33]]]]], [], []], "list", ["subexpr", "@mut", [["get", "comments", ["loc", [null, [1, 39], [1, 47]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [1, 1009]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});