define('milegacy/services/session', ['exports', 'ember', 'ember-data', 'ember-simple-auth/services/session'], function (exports, _ember, _emberData, _emberSimpleAuthServicesSession) {
  exports['default'] = _emberSimpleAuthServicesSession['default'].extend({
    store: _ember['default'].inject.service(),
    currentUser: (function () {
      if (this.get('isAuthenticated')) {
        var store = this.get('store');
        var currentUserId = this.get('data.authenticated.id');

        if (currentUserId) {
          return _emberData['default'].PromiseObject.create({
            promise: store.findRecord('user', currentUserId)
          });
        } else {
          // first approach towards a null user
          // currently this only provides null user in tests
          return store.createRecord('user');
        }
      } else {
        return null;
      }
    }).property('data.authenticated.id', 'isAuthenticated')
  });
});