define('milegacy/components/story-drop-zone', ['exports', 'ember', 'npm:lodash/collection/forEach', 'milegacy/models/file-upload'], function (exports, _ember, _npmLodashCollectionForEach, _milegacyModelsFileUpload) {

  var imageProcessOpts = {
    maxWidth: 1200,
    maxHeight: 1200,
    quality: 0.6
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    store: _ember['default'].inject.service(),
    validFileList: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'audio/wav', 'video/mp4', 'video/x-ms-wmv', 'video/avi', 'video/mpeg', 'video/mov', 'video/wmv', 'video/quicktime', 'video/x-flv'],
    invalidFiles: [],
    isExpanded: true,
    addFile: function addFile(file) {
      var upload = _milegacyModelsFileUpload['default'].create({ file: file, imageProcessOpts: imageProcessOpts });
      var medias = this.get('story.media');

      var dup = medias.findBy('file.id', upload.get('id'));

      if (dup) {
        return;
      }

      var store = this.get('store');
      var story = this.get('story');
      var media = store.createRecord('media', {
        story: story,
        file: upload,
        url: file.name,
        mimetype: file.type,
        status: 'created'
      });

      upload.set('record', media);

      medias.addObject(media);
    },
    actions: {
      addFiles: function addFiles(files) {
        var _this = this;

        this.invalidFiles = [];
        this.validFiles = [];
        (0, _npmLodashCollectionForEach['default'])(files, function (file) {
          if (_this.validFileList.indexOf(file.type) > -1) {
            _this.addFile(file);
          } else {
            _this.invalidFiles.pushObject(file.name);
          }
        });

        this.set('isExpanded', true);
        if (this.invalidFiles.length > 0) {

          var errorText = "We can not upload these files due to invalid file Type :" + this.invalidFiles.toString();
          this.sendAction("showError", errorText);
        }
      }
    }
  });
});