define('milegacy/components/upload-story', ['exports', 'ember', 'milegacy/utils/unwrap', 'milegacy/utils/transition-lock', 'milegacy/persistors/story', 'milegacy/components/milestone-sidebar', 'npm:moment'], function (exports, _ember, _milegacyUtilsUnwrap, _milegacyUtilsTransitionLock, _milegacyPersistorsStory, _milegacyComponentsMilestoneSidebar, _npmMoment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    isExpanded: false,
    store: _ember['default'].inject.service('store'),
    session: _ember['default'].inject.service('session'),
    userId: null,
    isErrorMessage: false,
    errorMsg: '',
    ajax: _ember['default'].inject.service(),
    persistor: (function () {
      var story = this.get('story');
      var ajax = this.get('ajax');
      return _milegacyPersistorsStory['default'].create({ story: story, ajax: ajax });
    }).property('story'),
    click: function click(event) {
      this.set('isExpanded', true);
      if (event.target.className == "add-to-timecapsule") {
        this.send('upload');
      }
    },
    isPublishable: (function () {
      var hasTitle = _ember['default'].isPresent(this.get('story.title'));
      var hasBody = _ember['default'].isPresent(this.get('story.body'));
      var hasMilestone = _ember['default'].isPresent((0, _milegacyUtilsUnwrap.unwrap)(this.get('story.milestone')));
      var isExpanded = this.get('isExpanded');

      return isExpanded && hasTitle && hasBody && hasMilestone;
    }).property('story.title', 'story.body', 'story.milestone', 'isExpanded'),

    isValidDate: (function () {
      var isDateAvail = _ember['default'].isPresent(this.get('story.publish_date'));
      var d = new Date(this.get('story.publish_date'));
      return isDateAvail && d instanceof Date;
    }).property('story.publish_date'),

    //attribute to used for disabling the publish later button when audience is only
    isPublishLaterDisabaled: (function () {
      return this.get('story.audience') === "only_me";
    }).property('story.audience', 'isPublishable'),

    disabledButtonTitle: (function () {
      if (this.get('isPublishLaterDisabaled')) {
        return "You can not publish later when you select the audience as only me !";
      }
      return "";
    }).property('isPublishLaterDisabaled'),

    isDisabled: _ember['default'].computed.not('isPublishable'),
    isReadyToPublish: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var i18n = this.get('i18n');
      this.unlock = (0, _milegacyUtilsTransitionLock.lock)(function (ask) {
        var story = _this.get('story');
        if (!story.get('isBlank') && ask(i18n.t('transition.uploader'))) {
          story.rollbackAttributes();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.unlock();
    },
    datepickerOptions: {
      max: (0, _npmMoment['default'])().add(_npmMoment['default'].duration(25, 'year')).toDate(),
      min: (0, _npmMoment['default'])(),
      selectYears: 100,
      selectMonths: true,
      format: 'mm-dd-yyyy',
      formatSubmit: 'yyyy-mm-dd'
    },
    actions: {
      upload: function upload() {
        var _this2 = this;

        this.send('openPublish');
        this.sendAction('upload', this.get('persistor'));
        var currentUser = this.get('session.currentUser');
        this.set('userId', currentUser.get('id'));
        var filter = { user_id: this.get('userId') };
        setTimeout(function () {
          (0, _milegacyComponentsMilestoneSidebar.refresh)(_this2.get('store'), 'story', filter);
        }, 2000);
      },
      openPublish: function openPublish() {
        this.set('isReadyToPublish', !this.get('isReadyToPublish'));
      },

      showError: function showError(errorMessage) {
        var _this3 = this;

        if (errorMessage && errorMessage.trim().length > 0) {
          this.set('errorMsg', errorMessage);
          this.set('isErrorMessage', true);
        }
        setTimeout(function () {
          _this3.set('errorMsg', '');
          _this3.set('isErrorMessage', false);
        }, 7000);
      }
    }
  });
});