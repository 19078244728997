define('milegacy/utils/sidebar', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports.decorateDigests = decorateDigests;
  exports.decorateMilestones = decorateMilestones;
  exports.decorateMilestone = decorateMilestone;
  exports.decorateFriendDigests = decorateFriendDigests;

  var DigestProxy = _ember['default'].ObjectProxy.extend({
    rootDigest: (function () {
      var store = this.get('store');
      var group = this.get('group');
      return store.peekRecord(this.get('subject') + '-digest', group + '-');
    }).property('digests.[]'),

    digest: (function () {
      var root = this.get('rootDigest');

      if (!root) {
        return;
      }

      var id = this.get('digestId');
      var digest = root.get('subdigests').findBy('groupId', id);
      return digest;
    }).property('rootDigest.subdigests.[]'),
    badge: _ember['default'].computed.alias('digest.count')
  });

  var SidebarList = _ember['default'].ArrayProxy.extend({
    length: (function () {
      return this.get('content.length') + 1;
    }).property('content.length'),
    objectAtContent: function objectAtContent(idx) {
      if (idx === 0) {
        return this.get('prompt');
      }

      var item = this.get('content').objectAt(idx - 1);

      return DigestProxy.create(_extends({
        content: item
      }, this.get('digestProps')));
    }
  });

  function decorateDigests(store, content, link, givenPrompt, subject, group) {
    var digestProps = arguments.length <= 6 || arguments[6] === undefined ? {} : arguments[6];

    var digests = store.peekAll(subject + '-digest');

    var Prompt = _ember['default'].Object.extend({
      digest: (function () {
        return store.peekRecord(subject + '-digest', group + '-');
      }).property('digests.[]'),
      badge: _ember['default'].computed.alias('digest.count')
    });

    var prompt = Prompt.create(_extends({
      digests: digests,
      icon: 'all',
      color: 'gray'
    }, givenPrompt));

    digestProps = _extends({
      store: store,
      subject: subject,
      group: group,
      digests: digests,
      link: link
    }, digestProps);

    return SidebarList.create({ content: content, prompt: prompt, digestProps: digestProps });
  }

  function decorateMilestones() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return decorateDigests.apply(undefined, args.concat(['milestone', {
      digestId: _ember['default'].computed.alias('id'),
      name: _ember['default'].computed.alias('title'),
      icon: _ember['default'].computed.alias('slug'),
      iconStyle: 'outline'
    }]));
  }

  function decorateMilestone(milestone) {
    return _ember['default'].ObjectProxy.create({
      content: milestone,
      text: _ember['default'].computed.alias('title'),
      icon: _ember['default'].computed.alias('slug'),
      iconColor: _ember['default'].computed.alias('color'),
      iconStyle: 'outline'
    });
  }

  var FriendsDigestProxy = _ember['default'].ObjectProxy.extend({
    rootDigest: (function () {
      var store = this.get('store');
      var group = this.get('group');
      if (group) {
        return store.peekRecord(this.get('subject'), group + '-');
      } else {
        return store.peekAll(this.get('subject'));
      }
    }).property('digests.[]'),

    digest: (function () {
      var root = this.get('rootDigest');

      if (!root) {
        return;
      }

      var id = this.get('digestId');

      if (id) {
        var digest = root.get('subdigests').findBy('groupId', id);
      } else {
        var digest = null;
      }
      return digest;
    }).property('rootDigest.subdigests.[]'),
    badge: _ember['default'].computed.alias('digest.count')
  });

  var FriendsSidebarList = _ember['default'].ArrayProxy.extend({
    length: (function () {
      return this.get('content.length') + 1;
    }).property('content.length'),
    objectAtContent: function objectAtContent(idx) {
      if (idx === 0) {
        return this.get('prompt');
      }

      var item = this.get('content').objectAt(idx - 1);
      var digestProps = this.get('newDigestPropsArray').objectAt(idx - 1);

      return FriendsDigestProxy.create(_extends({
        content: item
      }, digestProps));
    }
  });

  function decorateFriendDigests(store, content, link, givenPrompt) {
    var digestProps = arguments.length <= 4 || arguments[4] === undefined ? {} : arguments[4];

    var Prompt = _ember['default'].Object.extend({
      digest: (function () {
        var group = this.get('group');

        if (group) {
          return store.peekRecord(this.get('subject'), group + '-false');
        } else {
          return store.peekAll(this.get('subject'));
        }
      }).property('digests.[]'),
      badge: _ember['default'].computed.alias('digest.count')
    });

    var prompt;
    var newDigestPropsArray = [];

    content.forEach(function (element, index) {
      var subject = element.subject;
      var digests = store.peekAll(subject);
      var group = element.group;

      if (element.subject == "friendship-digest") {
        prompt = Prompt.create(_extends({
          digests: digests,
          color: 'gray',
          subject: subject,
          group: group
        }, givenPrompt));
      }

      var newDigestProps = _extends({
        store: store,
        subject: subject,
        group: group,
        digests: digests,
        link: link
      }, digestProps);
      newDigestPropsArray[index] = newDigestProps;
    });
    return FriendsSidebarList.create({ content: content, prompt: prompt, newDigestPropsArray: newDigestPropsArray });
  }
});