define("milegacy/adapters/friendship-digest", ["exports", "milegacy/adapters/application"], function (exports, _milegacyAdaptersApplication) {
  exports["default"] = _milegacyAdaptersApplication["default"].extend({
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var length;
      payload = Ember.copy(payload);
      if (Ember.isPresent(payload.included)) {
        length = payload.included.length;
      } else {
        length = 0;
      }

      switch (length) {
        case 0:
          payload = this.addBothEntries(payload);
          break;
        case 1:
          payload = this.addSingleEntry(payload);
          break;
      }

      return this._super(status, headers, payload, requestData);
    },

    addBothEntries: function addBothEntries(payload) {
      payload.included = [this._pendingAttributes(true), this._pendingAttributes(false)];
      payload.data[0].relationships.subdigests.data = [{ id: "pending-false", type: "friendship_digests" }, { id: "pending-true", type: "friendship_digests" }];
      return payload;
    },

    addSingleEntry: function addSingleEntry(payload) {
      var firstEntry = payload.included[0];
      if (firstEntry.id == 'pending-false') {
        payload.included[1] = this._pendingAttributes(true);
        payload.data[0].relationships.subdigests.data[1] = {
          id: "pending-true",
          type: "friendship_digests"
        };
      } else {
        payload.included[1] = this._pendingAttributes(false);
        payload.data[0].relationships.subdigests.data[1] = {
          id: "pending-false",
          type: "friendship_digests"
        };
      }
      return payload;
    },

    _pendingAttributes: function _pendingAttributes(group_id) {
      return {
        attributes: {
          count: 0,
          group_by: 'pending',
          group_id: group_id
        },
        id: "pending-" + group_id,
        relationships: {
          subdigests: { data: null },
          digests: { data: [] }
        },
        type: 'friendship_digests'
      };
    }
  });
});