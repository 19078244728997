define('milegacy/components/radio-popup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    searchNameKey: '',
    friendValue: _ember['default'].computed.alias('story.friendId'),
    pageService: _ember['default'].inject.service('page'),
    store: _ember['default'].inject.service(),
    friendsOption: [],
    isDisabled: (function () {
      return !_ember['default'].isPresent(this.get('friendValue'));
    }).property('friendValue'),

    didInsertElement: function didInsertElement() {
      this.getFriendsList();
    },

    isFriendAvailable: (function () {
      return this.get('friendsOption').length > 0;
    }).property('friendsOption'),

    filteredFriendsOption: (function () {
      var _this = this;

      if (this.get('searchNameKey').trim().length === 0) {
        return this.get('friendsOption');
      } else {
        return this.get('friendsOption').filter(function (friend) {
          if (friend.get('friendName')) {
            return friend.get('friendName').toLowerCase().indexOf(_this.get('searchNameKey').toLowerCase()) > -1;
          }
        });
      }
    }).property('friendsOption', 'searchNameKey'),

    getFriendsList: function getFriendsList() {
      var self = this;
      // Resolve the promise here.
      this.get('friendsOption').then(function (value) {
        self.set('friendsOption', self.get('store').peekAll('friendship').filterBy('pending', false));
      });
    },

    actions: {
      addAudienceToStory: function addAudienceToStory() {
        var modals = this.get('pageService.activeModals');
        if (this.get('friendValue')) {
          this.set('story.audience', 'individual');
          this.set('story.friendId', this.get('friendValue'));
        }
        modals.forEach(function (modal) {
          modal.hide();
        });
      }
    }

  });
});