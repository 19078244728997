define('milegacy/routes/user', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/utils/query-record-proxy'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyUtilsQueryRecordProxy) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    model: function model(_ref) {
      var userId = _ref.user_id;

      var store = this.get('store');
      var user = this.get('session.currentUser');
      var currentUserId = this.get('session.currentUser.id');
      var friendship;

      if (this.get('session.isAuthenticated') && userId !== currentUserId) {

        user = store.findRecord('user', userId);
        friendship = (0, _milegacyUtilsQueryRecordProxy.queryRecord)(store, 'friendship', ['userId', 'friendId'], { filter: { user_id: currentUserId, friend_id: userId } }, { isNew: false, isDeleted: false, userId: currentUserId, friendId: userId });
      }

      return _ember['default'].Object.create({
        user: user, userId: userId, friendship: friendship
      });
    }
  });
});