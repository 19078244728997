define("milegacy/templates/components/side-bar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 28
            },
            "end": {
              "line": 1,
              "column": 53
            }
          },
          "moduleName": "milegacy/templates/components/side-bar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "yield", ["loc", [null, [1, 44], [1, 53]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 324
                      },
                      "end": {
                        "line": 1,
                        "column": 386
                      }
                    },
                    "moduleName": "milegacy/templates/components/side-bar.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "badge");
                    var el2 = dom.createTextNode("99+");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 1,
                          "column": 386
                        },
                        "end": {
                          "line": 1,
                          "column": 461
                        }
                      },
                      "moduleName": "milegacy/templates/components/side-bar.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "badge");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                      return morphs;
                    },
                    statements: [["content", "timeCapsuleCount", ["loc", [null, [1, 434], [1, 454]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 386
                      },
                      "end": {
                        "line": 1,
                        "column": 461
                      }
                    },
                    "moduleName": "milegacy/templates/components/side-bar.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "timeCapsuleCount", ["loc", [null, [1, 396], [1, 412]]]]], [], 0, null, ["loc", [null, [1, 386], [1, 461]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 181
                    },
                    "end": {
                      "line": 1,
                      "column": 468
                    }
                  },
                  "moduleName": "milegacy/templates/components/side-bar.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "icon icon-calendar icon-timecapsule icon-outline");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "name Timecapsule");
                  var el2 = dom.createTextNode("Timecapsule");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "gt", [["get", "timeCapsuleCount", ["loc", [null, [1, 334], [1, 350]]]], 99], [], ["loc", [null, [1, 330], [1, 354]]]]], [], 0, 1, ["loc", [null, [1, 324], [1, 468]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 152
                  },
                  "end": {
                    "line": 1,
                    "column": 480
                  }
                },
                "moduleName": "milegacy/templates/components/side-bar.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [1, 192], [1, 201]]]], ["get", "item.id", ["loc", [null, [1, 202], [1, 209]]]]], [], 0, null, ["loc", [null, [1, 181], [1, 480]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 115
                },
                "end": {
                  "line": 1,
                  "column": 487
                }
              },
              "moduleName": "milegacy/templates/components/side-bar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "requestForCurrentUser", ["loc", [null, [1, 158], [1, 179]]]]], [], 0, null, ["loc", [null, [1, 152], [1, 487]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 506
                    },
                    "end": {
                      "line": 1,
                      "column": 623
                    }
                  },
                  "moduleName": "milegacy/templates/components/side-bar.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "side-bar-item", [], ["refreshTimeCapsuleCount", "refreshTimeCapsuleCount", "item", ["subexpr", "@mut", [["get", "item", ["loc", [null, [1, 607], [1, 611]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [1, 617], [1, 621]]]]], [], []]], ["loc", [null, [1, 536], [1, 623]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 487
                  },
                  "end": {
                    "line": 1,
                    "column": 635
                  }
                },
                "moduleName": "milegacy/templates/components/side-bar.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [1, 517], [1, 526]]]], ["get", "item.id", ["loc", [null, [1, 527], [1, 534]]]]], [], 0, null, ["loc", [null, [1, 506], [1, 635]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.2.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 1,
                            "column": 829
                          },
                          "end": {
                            "line": 1,
                            "column": 890
                          }
                        },
                        "moduleName": "milegacy/templates/components/side-bar.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "badge");
                        var el2 = dom.createTextNode("99+");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "fragmentReason": false,
                          "revision": "Ember@2.2.1",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 1,
                              "column": 890
                            },
                            "end": {
                              "line": 1,
                              "column": 963
                            }
                          },
                          "moduleName": "milegacy/templates/components/side-bar.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createElement("span");
                          dom.setAttribute(el1, "class", "badge");
                          var el2 = dom.createComment("");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                          return morphs;
                        },
                        statements: [["content", "totalStoryCount", ["loc", [null, [1, 937], [1, 956]]]]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.2.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 1,
                            "column": 890
                          },
                          "end": {
                            "line": 1,
                            "column": 963
                          }
                        },
                        "moduleName": "milegacy/templates/components/side-bar.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["block", "if", [["get", "totalStoryCount", ["loc", [null, [1, 900], [1, 915]]]]], [], 0, null, ["loc", [null, [1, 890], [1, 963]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 1,
                          "column": 704
                        },
                        "end": {
                          "line": 1,
                          "column": 970
                        }
                      },
                      "moduleName": "milegacy/templates/components/side-bar.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "icon icon-all icon-gray icon-outline");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "name All_Stories");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                      morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "item.name", ["loc", [null, [1, 809], [1, 822]]]], ["block", "if", [["subexpr", "gt", [["get", "totalStoryCount", ["loc", [null, [1, 839], [1, 854]]]], 99], [], ["loc", [null, [1, 835], [1, 858]]]]], [], 0, 1, ["loc", [null, [1, 829], [1, 970]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 675
                      },
                      "end": {
                        "line": 1,
                        "column": 982
                      }
                    },
                    "moduleName": "milegacy/templates/components/side-bar.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [1, 715], [1, 724]]]]], [], 0, null, ["loc", [null, [1, 704], [1, 982]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 635
                    },
                    "end": {
                      "line": 1,
                      "column": 989
                    }
                  },
                  "moduleName": "milegacy/templates/components/side-bar.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "requestForCurrentUser", ["loc", [null, [1, 681], [1, 702]]]]], [], 0, null, ["loc", [null, [1, 675], [1, 989]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 997
                      },
                      "end": {
                        "line": 1,
                        "column": 1096
                      }
                    },
                    "moduleName": "milegacy/templates/components/side-bar.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["inline", "side-bar-item", [], ["refreshTimeCapsuleCount", "refreshTimeCapsuleCount", "item", ["subexpr", "@mut", [["get", "item", ["loc", [null, [1, 1090], [1, 1094]]]]], [], []]], ["loc", [null, [1, 1019], [1, 1096]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 989
                    },
                    "end": {
                      "line": 1,
                      "column": 1108
                    }
                  },
                  "moduleName": "milegacy/templates/components/side-bar.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [1, 1008], [1, 1017]]]]], [], 0, null, ["loc", [null, [1, 997], [1, 1108]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 635
                  },
                  "end": {
                    "line": 1,
                    "column": 1108
                  }
                },
                "moduleName": "milegacy/templates/components/side-bar.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "item.name", ["loc", [null, [1, 649], [1, 658]]]], "All Stories"], [], ["loc", [null, [1, 645], [1, 673]]]]], [], 0, 1, ["loc", [null, [1, 635], [1, 1108]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 487
                },
                "end": {
                  "line": 1,
                  "column": 1108
                }
              },
              "moduleName": "milegacy/templates/components/side-bar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "item.id", ["loc", [null, [1, 497], [1, 504]]]]], [], 0, 1, ["loc", [null, [1, 487], [1, 1108]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 61
              },
              "end": {
                "line": 1,
                "column": 1120
              }
            },
            "moduleName": "milegacy/templates/components/side-bar.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "milestone");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "item.name", ["loc", [null, [1, 125], [1, 134]]]], "Timecapsules"], [], ["loc", [null, [1, 121], [1, 150]]]]], [], 0, 1, ["loc", [null, [1, 115], [1, 1115]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 53
            },
            "end": {
              "line": 1,
              "column": 1129
            }
          },
          "moduleName": "milegacy/templates/components/side-bar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "sidebarItems", ["loc", [null, [1, 69], [1, 81]]]]], [], 0, null, ["loc", [null, [1, 61], [1, 1129]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1141
          }
        },
        "moduleName": "milegacy/templates/components/side-bar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "class", "milestones-list");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [1, 34], [1, 42]]]]], [], 0, 1, ["loc", [null, [1, 28], [1, 1136]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});