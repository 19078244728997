define('milegacy/components/browse-button', ['exports', 'ember', 'npm:lodash/collection/forEach'], function (exports, _ember, _npmLodashCollectionForEach) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':browse-button'],
    validFileList: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'audio/wav', 'video/mp4', 'video/x-ms-wmv', 'video/avi', 'video/mpeg', 'video/mov', 'video/wmv', 'video/quicktime', 'video/x-flv'],
    invalidFiles: [],
    validFiles: [],
    actions: {
      onfile: function onfile(files) {
        this.validFiles = [];
        this.validateFiles(files);
        if (this.validFiles.length > 0) {
          this.sendAction('onfile', this.validFiles);
        }
        if (this.invalidFiles.length > 0) {
          var errorText = "We can not upload these files due to invalid file Type :" + this.invalidFiles.toString();
          this.sendAction("showError", errorText);
        }
      }
    },

    validateFiles: function validateFiles(files) {
      var _this = this;

      this.invalidFiles = [];
      (0, _npmLodashCollectionForEach['default'])(files, function (file) {
        if (_this.validFileList.indexOf(file.type) > -1) {
          _this.validFiles.pushObject(file);
        } else {
          _this.invalidFiles.pushObject(file.name);
        }
      });
    }
  });
});