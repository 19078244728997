define('milegacy/components/edit-story-widget', ['exports', 'ember', 'milegacy/utils/unwrap', 'milegacy/persistors/story', 'npm:moment', 'milegacy/utils/hasDirtyRelationships', 'milegacy/utils/rollbackRelationships', 'milegacy/components/milestone-sidebar'], function (exports, _ember, _milegacyUtilsUnwrap, _milegacyPersistorsStory, _npmMoment, _milegacyUtilsHasDirtyRelationships, _milegacyUtilsRollbackRelationships, _milegacyComponentsMilestoneSidebar) {
  exports['default'] = _ember['default'].Component.extend({
    ajax: _ember['default'].inject.service(),
    dateValue: '',
    isErrorMessage: false,
    errorMsg: '',
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    isEventDateAvilable: false,
    datePickerOptions: {
      max: (0, _npmMoment['default'])().add(_npmMoment['default'].duration(25, 'year')).toDate(),
      selectYears: 100,
      selectMonths: true,
      format: 'mm-dd-yyyy',
      formatSubmit: 'yyyy-mm-dd'
    },
    persistor: (function () {
      var story = (0, _milegacyUtilsUnwrap.unwrap)(this.get('story'));
      var ajax = this.get('ajax');
      return _milegacyPersistorsStory['default'].create({ story: story, ajax: ajax });
    }).property('story'),
    didInsertElement: function didInsertElement() {
      var $input = this.$('.story-title');
      $input.focus();
    },

    isStoryDirty: _ember['default'].computed('story.hasDirtyAttributes', 'story.media', 'story.milestone', 'dateValue', function () {
      var story = this.get('story');
      var isDirty = false;
      var dirtyRelationships = (0, _milegacyUtilsHasDirtyRelationships.hasDirtyRelationships)((0, _milegacyUtilsUnwrap.unwrap)(story), ['media', 'milestone']);
      if (story.get('hasDirtyAttributes') || dirtyRelationships || this.dateValue) {
        isDirty = true;
      }
      return isDirty;
    }),

    isValidDate: function isValidDate(date) {
      var isDateAvail = _ember['default'].isPresent(date);
      var d = new Date(this.get('story.publish_date'));
      return isDateAvail && d instanceof Date;
    },

    actionAfterSave: function actionAfterSave() {
      var _this2 = this;

      this.sendAction('toggle');
      var currentUser = this.get('session.currentUser');
      var userId = currentUser.get('id');
      var filter = { user_id: userId };
      var _this = this;
      setTimeout(function () {
        (0, _milegacyComponentsMilestoneSidebar.reloadTimeCapsules)(_this2.get('store'), filter).then(function () {
          (0, _milegacyComponentsMilestoneSidebar.refresh)(_this.get('store'), 'story', filter);
        });
        _this2.sendAction('refresh');
      }, 2000);
    },

    actions: {
      cancel: function cancel(story) {
        (0, _milegacyUtilsUnwrap.unwrap)(story).rollbackAttributes();
        (0, _milegacyUtilsRollbackRelationships.rollbackRelationships)((0, _milegacyUtilsUnwrap.unwrap)(story), ['media', 'milestone']);
        this.sendAction('toggle');
      },
      save: function save() {
        var self = this;
        var persistor = this.get('persistor');
        if (this.dateValue.toString().trim().length > 0 && this.isValidDate(this.dateValue)) {
          this.story.set('publish_date', this.dateValue);
        }
        var promise = persistor.save();
        promise.then(function () {
          persistor.deleteOrphanedMedia().then(function () {
            persistor.saveMedia();
          });
        });
        self.actionAfterSave();
      },

      setDateValue: function setDateValue(date) {
        this.set('dateValue', date);
      },

      showError: function showError(errorMessage) {
        var _this3 = this;

        if (errorMessage && errorMessage.trim().length > 0) {
          this.set('errorMsg', errorMessage);
          this.set('isErrorMessage', true);
        }
        setTimeout(function () {
          _this3.set('errorMsg', '');
          _this3.set('isErrorMessage', false);
        }, 7000);
      },

      closeModal: function closeModal() {}
    }
  });
});