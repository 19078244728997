define('milegacy/models/friends-suggestion', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    fullName: _emberData['default'].attr('string'),
    profilePictureUrl: _emberData['default'].attr('string'),
    bio: _emberData['default'].attr('string'),
    birthday: _emberData['default'].attr('utc'),
    location: _emberData['default'].attr('string'),
    acceptance_flag: _emberData['default'].attr('boolean', { defaultValue: false }),
    avatarUrl: (function () {
      return this.get('profilePictureUrl');
    }).property('profilePictureUrl')
  });
});