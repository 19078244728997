define("milegacy/templates/components/upload-story", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 187
              },
              "end": {
                "line": 1,
                "column": 505
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 224], [1, 229]]]]], [], []], "for", "title", "value", ["subexpr", "@mut", [["get", "story.title", ["loc", [null, [1, 248], [1, 259]]]]], [], []], "placeholder", "Title your story.", "autofocus", true, "class", "story-title input-minimal"], ["loc", [null, [1, 205], [1, 342]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 365], [1, 370]]]]], [], []], "for", "body", "value", ["subexpr", "@mut", [["get", "story.body", ["loc", [null, [1, 388], [1, 398]]]]], [], []], "placeholder", "Write a description.", "autosize", true, "rows", 2, "type", "textarea", "class", "story-body input-minimal"], ["loc", [null, [1, 346], [1, 505]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 505
              },
              "end": {
                "line": 1,
                "column": 561
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "story-prompt");
            var el2 = dom.createTextNode("Tell your story…");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 1179
                  },
                  "end": {
                    "line": 1,
                    "column": 1430
                  }
                },
                "moduleName": "milegacy/templates/components/upload-story.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "publish-actions button button-minimal submit");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon icon-calendar icon-gray icon-outline");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Publish Later");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element1, 'disabled');
                morphs[1] = dom.createAttrMorph(element1, 'title');
                return morphs;
              },
              statements: [["attribute", "disabled", ["get", "isPublishLaterDisabaled", ["loc", [null, [1, 1229], [1, 1252]]]]], ["attribute", "title", ["get", "disabledButtonTitle", ["loc", [null, [1, 1263], [1, 1282]]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 1501
                      },
                      "end": {
                        "line": 1,
                        "column": 1750
                      }
                    },
                    "moduleName": "milegacy/templates/components/upload-story.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "publish-actions button button-minimal submit");
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "icon icon-calendar icon-gray icon-outline");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createTextNode("Publish Later");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [0]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element0, 'disabled');
                    morphs[1] = dom.createAttrMorph(element0, 'title');
                    return morphs;
                  },
                  statements: [["attribute", "disabled", ["get", "isPublishLaterDisabaled", ["loc", [null, [1, 1549], [1, 1572]]]]], ["attribute", "title", ["get", "disabledButtonTitle", ["loc", [null, [1, 1583], [1, 1602]]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.2.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 1,
                            "column": 2110
                          },
                          "end": {
                            "line": 1,
                            "column": 2215
                          }
                        },
                        "moduleName": "milegacy/templates/components/upload-story.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createElement("button");
                        dom.setAttribute(el1, "type", "submit");
                        dom.setAttribute(el1, "class", "add-to-timecapsule");
                        var el2 = dom.createTextNode("Add to Timecapsule");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 1,
                          "column": 2091
                        },
                        "end": {
                          "line": 1,
                          "column": 2232
                        }
                      },
                      "moduleName": "milegacy/templates/components/upload-story.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 2132], [1, 2137]]]]], [], []]], 0, null, ["loc", [null, [1, 2110], [1, 2232]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.2.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 1,
                          "column": 2232
                        },
                        "end": {
                          "line": 1,
                          "column": 2311
                        }
                      },
                      "moduleName": "milegacy/templates/components/upload-story.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1, "disabled", "");
                      dom.setAttribute(el1, "class", "add-to-timecapsule");
                      var el2 = dom.createTextNode("Add to Timecapsule");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.2.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 1,
                        "column": 1767
                      },
                      "end": {
                        "line": 1,
                        "column": 2318
                      }
                    },
                    "moduleName": "milegacy/templates/components/upload-story.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("h3");
                    dom.setAttribute(el1, "class", "timecapsules-header");
                    var el2 = dom.createTextNode("Add to timecapsule");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "sub-header");
                    var el2 = dom.createTextNode("Please choose a date when you would like to publish this post");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["inline", "date-field", [], ["model", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 1982], [1, 1987]]]]], [], []], "value", ["subexpr", "@mut", [["get", "story.publish_date", ["loc", [null, [1, 1994], [1, 2012]]]]], [], []], "placeholder", "Choose date", "options", ["subexpr", "@mut", [["get", "datepickerOptions", ["loc", [null, [1, 2047], [1, 2064]]]]], [], []], "class", "date-timecapsule"], ["loc", [null, [1, 1963], [1, 2091]]]], ["block", "if", [["get", "isValidDate", ["loc", [null, [1, 2097], [1, 2108]]]]], [], 0, 1, ["loc", [null, [1, 2091], [1, 2318]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 1472
                    },
                    "end": {
                      "line": 1,
                      "column": 2329
                    }
                  },
                  "moduleName": "milegacy/templates/components/upload-story.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1523], [1, 1528]]]]], [], []]], 0, null, ["loc", [null, [1, 1501], [1, 1767]]]], ["block", "pop-up", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1783], [1, 1788]]]]], [], []], "class", "timecapsules-popup"], 1, null, ["loc", [null, [1, 1767], [1, 2329]]]]],
                locals: ["modal"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 1437
                  },
                  "end": {
                    "line": 1,
                    "column": 2347
                  }
                },
                "moduleName": "milegacy/templates/components/upload-story.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "modal-context", [], [], 0, null, ["loc", [null, [1, 1472], [1, 2347]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 938
                },
                "end": {
                  "line": 1,
                  "column": 2364
                }
              },
              "moduleName": "milegacy/templates/components/upload-story.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "actions-publish");
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "type", "submit");
              dom.setAttribute(el2, "class", "publish-actions button button-minimal submit create_normal_story");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "icon icon-arrow-right icon-gray icon-outline");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("Publish Now");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 2, 2);
              return morphs;
            },
            statements: [["block", "if", [["get", "isPublishLaterDisabaled", ["loc", [null, [1, 1185], [1, 1208]]]]], [], 0, null, ["loc", [null, [1, 1179], [1, 1437]]]], ["block", "unless", [["get", "isPublishLaterDisabaled", ["loc", [null, [1, 1447], [1, 1470]]]]], [], 1, null, ["loc", [null, [1, 1437], [1, 2358]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 605
              },
              "end": {
                "line": 1,
                "column": 2377
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "spacer");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "control publish publish-post");
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "publish-actions button button-minimal submit");
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("Publish Post");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "icon-empty icon-down");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [0]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element4, 'disabled');
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "isDisabled", ["loc", [null, [1, 766], [1, 776]]]]], ["element", "action", ["openPublish"], ["on", "click"], ["loc", [null, [1, 779], [1, 814]]]], ["block", "if", [["get", "isReadyToPublish", ["loc", [null, [1, 944], [1, 960]]]]], [], 0, null, ["loc", [null, [1, 938], [1, 2371]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 2417
              },
              "end": {
                "line": 1,
                "column": 2510
              }
            },
            "moduleName": "milegacy/templates/components/upload-story.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "errors");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "error");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
            return morphs;
          },
          statements: [["content", "errorMsg", ["loc", [null, [1, 2490], [1, 2502]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 2517
            }
          },
          "moduleName": "milegacy/templates/components/upload-story.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "class", "fieldset");
          var el2 = dom.createElement("fieldset");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(element5, [0]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createAttrMorph(element6, 'disabled');
          morphs[2] = dom.createMorphAt(element6, 0, 0);
          morphs[3] = dom.createMorphAt(element6, 1, 1);
          morphs[4] = dom.createMorphAt(element6, 2, 2);
          morphs[5] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["element", "action", ["upload"], ["on", "submit"], ["loc", [null, [1, 96], [1, 127]]]], ["attribute", "disabled", ["get", "persistor.isSaving", ["loc", [null, [1, 166], [1, 184]]]]], ["block", "if", [["get", "isExpanded", ["loc", [null, [1, 193], [1, 203]]]]], [], 0, 1, ["loc", [null, [1, 187], [1, 568]]]], ["inline", "upload-manager", [], ["medias", ["subexpr", "@mut", [["get", "story.media", ["loc", [null, [1, 592], [1, 603]]]]], [], []]], ["loc", [null, [1, 568], [1, 605]]]], ["block", "story-control-bar", [], ["story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 632], [1, 637]]]]], [], []], "addFiles", ["subexpr", "@mut", [["get", "addFiles", ["loc", [null, [1, 647], [1, 655]]]]], [], []], "showError", "showError"], 2, null, ["loc", [null, [1, 605], [1, 2399]]]], ["block", "if", [["get", "isErrorMessage", ["loc", [null, [1, 2423], [1, 2437]]]]], [], 3, null, ["loc", [null, [1, 2417], [1, 2517]]]]],
        locals: ["addFiles"],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 2537
          }
        },
        "moduleName": "milegacy/templates/components/upload-story.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "story-drop-zone", [], ["story", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 25], [1, 30]]]]], [], []], "showError", "showError", "isExpanded", ["subexpr", "@mut", [["get", "isExpanded", ["loc", [null, [1, 64], [1, 74]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [1, 2537]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});