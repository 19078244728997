define('milegacy/routes/index', ['exports', 'ember', 'milegacy/routes/base', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyRoutesBase, _milegacyUtilsUnwrap) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    session: _ember['default'].inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var currentUser = (0, _milegacyUtilsUnwrap.unwrap)(this.get('session.currentUser'));

      if (currentUser && currentUser.get('birthday') && currentUser.get('acceptance_flag')) {
        this.transitionTo('notifications');
      } else {
        if (_ember['default'].isPresent(transition.queryParams.email)) {
          this.transitionTo('authenticate', { queryParams: { email: transition.queryParams.email.replace(" ", "+") } });
        } else {
          this.transitionTo('authenticate');
        }
      }
    }
  });
});