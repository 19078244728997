define('milegacy/components/search-widget', ['exports', 'ember', 'milegacy/utils/dom', 'milegacy/utils/observable-from', 'npm:rx'], function (exports, _ember, _milegacyUtilsDom, _milegacyUtilsObservableFrom, _npmRx) {

  var minLength = function minLength(string) {
    return string && string.length > 2;
  };

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':search-bar', 'modal.visible:active'],
    hideModalOnEmpty: false,
    results: [],
    showResults: (function () {
      var visible = this.get('modal.visible');
      var query = this.get('query');
      return visible && _ember['default'].isPresent(query);
    }).property('modal.visible', 'query'),
    queryChanged: (function () {
      this.onSearchEvent();
    }).observes('query'),
    queries: (0, _milegacyUtilsObservableFrom['default'])('query'),
    queryObserver: (function () {
      var _this = this;

      var queryStream = this.get('queries').filter(minLength).debounce(200).distinctUntilChanged();

      var typeStream = this.get('queries').map(minLength);

      typeStream.subscribe(function (valid) {
        _this.set('valid', valid);
      });

      var resultStream = queryStream.flatMapLatest(_ember['default'].run.bind(this, this.requestSearch));

      resultStream.subscribe(function (results) {
        _this.set('results', results);
      });

      var loadingStream = _npmRx['default'].Observable.merge(queryStream.map(true), resultStream.map(false));

      loadingStream.subscribe(function (state) {
        _this.set('loading', state);
      });
    }).on('init'),
    pageService: _ember['default'].inject.service('page'),
    onSearchEvent: function onSearchEvent() {
      var query = this.get('query');
      var page = this.get('pageService');
      var modal = this.get('modal');
      var visible = this.get('modal.visible');
      var hideModalOnEmpty = this.get('hideModalOnEmpty');

      var hasQuery = _ember['default'].isPresent(query);
      page.set('search', hasQuery);

      var newState = null;

      if (hasQuery) {
        if (!visible) {
          newState = true;
        }
      } else if (hideModalOnEmpty) {
        newState = false;
      }

      if (newState != null) {
        this.set('hideModalOnEmpty', newState);
        modal.toggle(newState);
      }
    },
    store: _ember['default'].inject.service('search-store'),
    requestSearch: function requestSearch(term) {
      var store = this.get('store');
      var promise = store.query('search', { filter: { term: term } });

      this.set('promise', promise);
      return promise;
    },
    actions: {
      focus: function focus() {
        if (!this.get('modal.visible')) {
          var $input = this.$('.search-input');
          (0, _milegacyUtilsDom.select)($input);
          $input.one('mouseup', function (event) {
            return event.preventDefault();
          });
        }

        this.onSearchEvent();
      },
      cancel: function cancel() {
        this.set('query', '');
      },
      jump: function jump() {
        this.$('.search-input').blur();
        this.$('.search-result.current').click();
      },

      clearSearchField: function clearSearchField() {
        this.send('cancel');
      }
    }
  });
});