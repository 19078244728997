define('milegacy/components/friend-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':friend-list', ':list'],
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    currentUser: _ember['default'].computed.alias('session.currentUser'),
    actions: {
      add_friend: function add_friend(friendship) {
        var _this = this;

        var store = this.get('store');
        var user = this.get('currentUser');
        store.findRecord('user', friendship.get('id')).then(function (friend) {
          var newFriendship = store.createRecord('friendship', { user: user, friend: friend, pending: true });
          newFriendship.save().then(function () {
            store.unloadRecord(friendship);
            _this.sendAction('refresh');
          });
        });
      }
    }
  });
});