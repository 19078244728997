define("milegacy/templates/components/comment-widget", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 111
                },
                "end": {
                  "line": 1,
                  "column": 196
                }
              },
              "moduleName": "milegacy/templates/components/comment-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-gray icon-remove-small comment");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 82
              },
              "end": {
                "line": 1,
                "column": 299
              }
            },
            "moduleName": "milegacy/templates/components/comment-widget.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 133], [1, 138]]]]], [], []]], 0, null, ["loc", [null, [1, 111], [1, 213]]]], ["inline", "binary-popup", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 234], [1, 239]]]]], [], []], "yes", "remove", "prompt", ["subexpr", "t", ["comment.destroy"], ["name", ["get", "model.body", ["loc", [null, [1, 286], [1, 296]]]]], ["loc", [null, [1, 260], [1, 297]]]]], ["loc", [null, [1, 213], [1, 299]]]]],
          locals: ["modal"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 323
            }
          },
          "moduleName": "milegacy/templates/components/comment-widget.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "remove");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["block", "modal-context", [], [], 0, null, ["loc", [null, [1, 82], [1, 317]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 330
            },
            "end": {
              "line": 1,
              "column": 378
            }
          },
          "moduleName": "milegacy/templates/components/comment-widget.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "badge badge-avatar");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 385
            },
            "end": {
              "line": 1,
              "column": 480
            }
          },
          "moduleName": "milegacy/templates/components/comment-widget.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "avatar-image", [], ["src", ["subexpr", "@mut", [["get", "model.user.avatarUrl", ["loc", [null, [1, 443], [1, 463]]]]], [], []], "class", "avatar"], ["loc", [null, [1, 424], [1, 480]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 676
          }
        },
        "moduleName": "milegacy/templates/components/comment-widget.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("header");
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "class", "author");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "time");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "isCurrentUserComment", ["loc", [null, [1, 10], [1, 30]]]], ["subexpr", "is-current-user", [["get", "story.user", ["loc", [null, [1, 48], [1, 58]]]]], [], ["loc", [null, [1, 31], [1, 59]]]]], [], ["loc", [null, [1, 6], [1, 60]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 330]]]], ["block", "if", [["get", "unread", ["loc", [null, [1, 336], [1, 342]]]]], [], 1, null, ["loc", [null, [1, 330], [1, 385]]]], ["block", "link-to", ["milestones", ["get", "model.user.id", ["loc", [null, [1, 409], [1, 422]]]]], [], 2, null, ["loc", [null, [1, 385], [1, 492]]]], ["content", "model.user.fullName", ["loc", [null, [1, 519], [1, 542]]]], ["inline", "format-date", [["get", "model.createdAt", ["loc", [null, [1, 578], [1, 593]]]], "MMM D | h:mm A"], [], ["loc", [null, [1, 564], [1, 612]]]], ["inline", "markdown", [["get", "model.body", ["loc", [null, [1, 658], [1, 668]]]]], [], ["loc", [null, [1, 647], [1, 670]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});