define('milegacy/components/friend-sidebar', ['exports', 'ember', 'milegacy/utils/sidebar'], function (exports, _ember, _milegacyUtilsSidebar) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports.refresh = refresh;

  function refresh(store) {
    var filter = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    store.queryRecord('friendship-digest', {
      filter: _extends({
        group_by: 'pending'
      }, filter)
    });
  }

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    friends: (function () {
      var store = this.get('store');
      var userId = this.get('session.currentUser.id');

      refresh(store, { userId: userId });

      var prompt = this.get('prompt');
      var link = this.get('link');

      var content = [

      //{ link, digestId: 'false', name: 'Accepted Friends', id: 'accepted' },
      { link: link, digestId: 'true', name: 'Pending', id: 'pending', subject: 'friendship-digest', group: 'pending' }, { link: link, digestId: null, name: 'Suggested', id: 'suggested', subject: 'friends-suggestion' }];

      // return decorateDigests(store, content, link, prompt, 'friendship', 'pending');
      return (0, _milegacyUtilsSidebar.decorateFriendDigests)(store, content, link, prompt);
    }).property('prompt')
  });
});