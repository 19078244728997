define('milegacy/models/user', ['exports', 'ember-data', 'milegacy/mixins/rollback'], function (exports, _emberData, _milegacyMixinsRollback) {
  exports['default'] = _emberData['default'].Model.extend(_milegacyMixinsRollback['default'], {
    email: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),
    passwordConfirmation: _emberData['default'].attr('string'),
    passwordAuthorization: _emberData['default'].attr('string'),
    fullName: _emberData['default'].attr('string'),
    profilePicture: _emberData['default'].attr('file'),
    profilePictureUrl: _emberData['default'].attr('string'),
    gender: _emberData['default'].attr('string'),
    avatarUrl: (function () {
      var file = this.get('profilePicture');
      var url = this.get('profilePictureUrl');

      if (file) {
        return file.get('url');
      } else {
        return url;
      }
    }).property('profilePicture.url', 'profilePictureUrl'),
    location: _emberData['default'].attr('string'),
    bio: _emberData['default'].attr('string'),
    birthday: _emberData['default'].attr('utc'),
    acceptance_flag: _emberData['default'].attr('boolean'),
    stories: _emberData['default'].hasMany('story'),
    friendships: _emberData['default'].hasMany('friendship', { inverse: 'user' })
  });
});