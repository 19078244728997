define('milegacy/routes/wp/login', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    'page:title': 'MiLegacy',
    'page:banner': 'MiLegacy',
    credentials: _ember['default'].inject.service('credentials'),
    session: _ember['default'].inject.service('session'),
    user: '',
    errors: '',
    model: function model() {
      var _get$getProperties = this.get('credentials').getProperties('email', 'password');

      var email = _get$getProperties.email;
      var password = _get$getProperties.password;

      return _ember['default'].Object.create({
        email: email, password: password,
        operation: this.createOperation()
      });
    },

    actions: {
      authenticate: function authenticate(model) {
        var _this = this;

        var session = this.get('session');
        var isAuthenticated = session.get('isAuthenticated');

        var promise = session.authenticate('authenticator:application', model.email, model.password)['catch'](function (data) {
          model.set('error', data.error);
        }).then(function () {
          if (isAuthenticated) {
            _this.transitionTo('index');
          }
        });

        this.updateOperation(promise);
      },

      willTransition: function willTransition() {
        var model = this.get('controller.model');
        this.get('credentials').setProperties(model.getProperties('email', 'password'));

        return true;
      }
    }
  });
});