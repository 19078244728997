define('ember-screen/breakpoint', ['exports'], function (exports) {
  'use strict';

  exports['default'] = breakpoint;

  function breakpoint(mediaQuery) {
    return Ember.computed('width', function () {
      return this.win.matchesMediaQuery(mediaQuery);
    });
  }
});