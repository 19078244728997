define('milegacy/routes/account/password/reset', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    devise: _ember['default'].inject.service('devise'),
    model: function model(params, transition) {
      return _ember['default'].Object.create({
        token: transition.queryParams.token,
        email: transition.queryParams.email,
        operation: this.createOperation()
      });
    },

    validPassword: function validPassword(password, model) {
      var passwordErrors = [];
      model.set('errors', []);
      var flash = this.get('flashMessages');
      if (_ember['default'].isBlank(password)) {
        passwordErrors.push('cannot be blank.');
      } else {
        if (password.length < 8) {
          passwordErrors.push('must have at least 8 characters');
        } else {
          if (password.search(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/) < 0) {
            passwordErrors.push('must contain a capital letter, a lowercase letter, a special character and a number');
          }

          // if(password.search(/[0-9]/) < 0) {
          //   passwordErrors.push('must contain at least one digit');
          // }
          // if(password.search(/[A-Z]/) < 0) {
          //   passwordErrors.push('must contain at least capital letter');
          // }
          // if(password.search(/[a-z]/) < 0) {
          //   passwordErrors.push('must contain at least one letter');
          // }
          // if(password.search(/[-\/\\^$*+?.()|[\]{}!@#%&<>;:=+_"'~`]/) < 0) {
          //   passwordErrors.push('must contain at least one special character');
          // }
        }
      }
      if (passwordErrors.length > 0) {
        model.set('errors', { password: [passwordErrors.join(', ')] });
        return false;
      }
      return true;
    },

    confirmedPassword: function confirmedPassword(password, password_confirmation, model) {
      if (_ember['default'].isBlank(model.get('errors'))) {
        if (password == password_confirmation) {
          return true;
        } else {
          model.set('errors', { password: ['must match its confirmation.'] });
          return false;
        }
      }
    },

    actions: {
      submit: function submit(model) {
        var _this = this;

        var flash = this.get('flashMessages');
        var password = model.get('password');
        var password_confirmation = model.get('password_confirmation');

        if (this.validPassword(password, model) && this.confirmedPassword(password, password_confirmation, model)) {
          var promise = this.get('devise').resetPassword(password, password_confirmation, model.get('token')).then(function () {
            _ember['default'].run(function () {
              return flash.success(_this.get('i18n').t('flash.account.password.reset.success'));
            });
            return _this.transitionTo('login', { queryParams: { email: model.get('email').replace(' ', '+') } });
          })['catch'](function (data) {
            var errors = data.responseJSON.errors;

            if (errors.reset_password_token) {
              model.set('errors', { password: ['reset link has expired.'] });
            } else {
              model.set('errors', data.responseJSON.errors);
            }
          });

          this.updateOperation(promise);
        }
      }
    }
  });
});