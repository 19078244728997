define('milegacy/components/profile-widget', ['exports', 'ember', 'milegacy/models/file-upload', 'milegacy/utils/unwrap', 'npm:moment'], function (exports, _ember, _milegacyModelsFileUpload, _milegacyUtilsUnwrap, _npmMoment) {

  var imageProcessOpts = {
    maxWidth: 192,
    maxHeight: 192,
    quality: 0.8
  };

  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    currentUser: _ember['default'].computed.alias('session.currentUser'),
    classNameBindings: [':cell', ':align-top', ':profile'],
    isCurrentUser: (function () {
      return this.get('user.id') === this.get('currentUser.id');
    }).property('user', 'currentUser'),
    ageInYears: (function () {
      var today = (0, _npmMoment['default'])();
      var birthday = this.get('user.birthday');
      if (!birthday) {
        return null;
      }
      return _npmMoment['default'].duration(today.diff(birthday)).years();
    }).property('user.birthday'),
    datepickerOptions: {
      max: (0, _npmMoment['default'])().add(_npmMoment['default'].duration(1, 'year')).toDate(),
      selectYears: 100,
      selectMonths: true,
      format: 'mm-dd-yyyy',
      formatSubmit: 'yyyy-mm-dd'
    },
    hasFriendship: (function () {
      var friendship = this.get('friendship');
      return _ember['default'].isPresent((0, _milegacyUtilsUnwrap.unwrap)(friendship));
    }).property('friendship', 'friendship.content'),
    actions: {
      refresh: function refresh(payload) {
        this.sendAction('refresh', payload);
      },
      edit: function edit() {
        this.set('editing', true);
      },
      save: function save(user) {
        var _this = this;

        (0, _milegacyUtilsUnwrap.unwrap)(user).save().then(function () {
          _this.set('editing', false);
        });
      },
      cancel: function cancel(user) {
        (0, _milegacyUtilsUnwrap.unwrap)(user).rollbackAttributes();
        this.set('editing', false);
      },
      unfriend: function unfriend() {
        var _this2 = this;

        (0, _milegacyUtilsUnwrap.unwrap)(this.get('friendship')).destroyRecord().then(function () {
          _this2.sendAction('refresh');
        });
      },
      addAvatar: function addAvatar(files) {
        var file = _milegacyModelsFileUpload['default'].create({ file: files[0], imageProcessOpts: imageProcessOpts });
        var user = this.get('user');
        user.set('profilePicture', file);
      }
    }
  });
});