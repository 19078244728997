define("milegacy/templates/components/user-group", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 1,
              "column": 44
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "scroll-to", [], ["target", ["subexpr", "@mut", [["get", "element", ["loc", [null, [1, 35], [1, 42]]]]], [], []]], ["loc", [null, [1, 16], [1, 44]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 51
            },
            "end": {
              "line": 1,
              "column": 75
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: true,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 119
              },
              "end": {
                "line": 1,
                "column": 167
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "badge badge-avatar");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 174
              },
              "end": {
                "line": 1,
                "column": 257
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "avatar-image", [], ["src", ["subexpr", "@mut", [["get", "user.avatarUrl", ["loc", [null, [1, 226], [1, 240]]]]], [], []], "class", "avatar"], ["loc", [null, [1, 207], [1, 257]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 393
                  },
                  "end": {
                    "line": 1,
                    "column": 429
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("All Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 442
                  },
                  "end": {
                    "line": 1,
                    "column": 480
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("All Unread");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 493
                  },
                  "end": {
                    "line": 1,
                    "column": 538
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Unread Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 551
                  },
                  "end": {
                    "line": 1,
                    "column": 602
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Unread Comments");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 615
                  },
                  "end": {
                    "line": 1,
                    "column": 665
                  }
                },
                "moduleName": "milegacy/templates/components/user-group.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Followed Stories");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 369
                },
                "end": {
                  "line": 1,
                  "column": 678
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "x-option", [], ["value", "all"], 0, null, ["loc", [null, [1, 393], [1, 442]]]], ["block", "x-option", [], ["value", "unread"], 1, null, ["loc", [null, [1, 442], [1, 493]]]], ["block", "x-option", [], ["value", "new_story"], 2, null, ["loc", [null, [1, 493], [1, 551]]]], ["block", "x-option", [], ["value", "story_activity"], 3, null, ["loc", [null, [1, 551], [1, 615]]]], ["block", "x-option", [], ["value", "follow_story"], 4, null, ["loc", [null, [1, 615], [1, 678]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 325
              },
              "end": {
                "line": 1,
                "column": 737
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "select-wrapper");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-mini icon-down");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["block", "x-select", [], ["value", ["subexpr", "@mut", [["get", "kind", ["loc", [null, [1, 387], [1, 391]]]]], [], []]], 0, null, ["loc", [null, [1, 369], [1, 691]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 737
              },
              "end": {
                "line": 1,
                "column": 760
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "countString", ["loc", [null, [1, 745], [1, 760]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 792
                },
                "end": {
                  "line": 1,
                  "column": 854
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("h3");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "timeCapsuleCountString", ["loc", [null, [1, 823], [1, 849]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 772
              },
              "end": {
                "line": 1,
                "column": 861
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasTimeCapsuleStory", ["loc", [null, [1, 798], [1, 817]]]]], [], 0, null, ["loc", [null, [1, 792], [1, 861]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1042
              },
              "end": {
                "line": 1,
                "column": 1096
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-gray icon-up");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1096
              },
              "end": {
                "line": 1,
                "column": 1144
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-gray icon-down");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1204
                },
                "end": {
                  "line": 1,
                  "column": 1281
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-gray icon-remove-small");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1175
              },
              "end": {
                "line": 1,
                "column": 1427
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-toggle", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1226], [1, 1231]]]]], [], []]], 0, null, ["loc", [null, [1, 1204], [1, 1298]]]], ["inline", "binary-popup", [], ["modal", ["subexpr", "@mut", [["get", "modal", ["loc", [null, [1, 1319], [1, 1324]]]]], [], []], "yes", "deleteFromUser", "prompt", ["subexpr", "t", ["notification.prompts.delete-group-notifications"], ["name", ["get", "user.fullName", ["loc", [null, [1, 1411], [1, 1424]]]]], ["loc", [null, [1, 1353], [1, 1425]]]]], ["loc", [null, [1, 1298], [1, 1427]]]]],
          locals: ["modal"],
          templates: [child0]
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1504
              },
              "end": {
                "line": 1,
                "column": 1624
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "notification-widget", [], ["notification", ["subexpr", "@mut", [["get", "notification", ["loc", [null, [1, 1594], [1, 1606]]]]], [], []], "refresh", ["subexpr", "@mut", [["get", "refresh", ["loc", [null, [1, 1615], [1, 1622]]]]], [], []]], ["loc", [null, [1, 1559], [1, 1624]]]]],
          locals: ["notification"],
          templates: []
        };
      })();
      var child9 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 1675
                },
                "end": {
                  "line": 1,
                  "column": 1853
                }
              },
              "moduleName": "milegacy/templates/components/user-group.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "empty-list");
              var el2 = dom.createElement("h1");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "t-ui");
              var el3 = dom.createTextNode("Try changing the filter to All Stories.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "concat", ["notification.empty_states.", ["get", "kind", ["loc", [null, [1, 1776], [1, 1780]]]]], [], ["loc", [null, [1, 1739], [1, 1781]]]]], [], ["loc", [null, [1, 1735], [1, 1783]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1639
              },
              "end": {
                "line": 1,
                "column": 1864
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "notifications.length", ["loc", [null, [1, 1685], [1, 1705]]]]], [], 0, null, ["loc", [null, [1, 1675], [1, 1864]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child10 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 1875
              },
              "end": {
                "line": 1,
                "column": 1933
              }
            },
            "moduleName": "milegacy/templates/components/user-group.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "countable-loader", [], ["records", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [1, 1918], [1, 1931]]]]], [], []]], ["loc", [null, [1, 1891], [1, 1933]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 75
            },
            "end": {
              "line": 1,
              "column": 1946
            }
          },
          "moduleName": "milegacy/templates/components/user-group.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "cell notification");
          var el2 = dom.createElement("div");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "titles");
          var el3 = dom.createElement("h2");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "actions");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "action");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "action");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [0]);
          var element2 = dom.childAt(element0, [1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element0, [2]);
          var element5 = dom.childAt(element4, [0]);
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(14);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(element2, 2, 2);
          morphs[5] = dom.createMorphAt(element3, 0, 0);
          morphs[6] = dom.createMorphAt(element3, 2, 2);
          morphs[7] = dom.createElementMorph(element5);
          morphs[8] = dom.createMorphAt(element5, 0, 0);
          morphs[9] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[10] = dom.createAttrMorph(element6, 'class');
          morphs[11] = dom.createMorphAt(element6, 0, 0);
          morphs[12] = dom.createMorphAt(element6, 1, 1);
          morphs[13] = dom.createMorphAt(element6, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "unread", ["loc", [null, [1, 125], [1, 131]]]]], [], 0, null, ["loc", [null, [1, 119], [1, 174]]]], ["block", "link-to", ["milestones", ["get", "user.id", ["loc", [null, [1, 198], [1, 205]]]]], [], 1, null, ["loc", [null, [1, 174], [1, 269]]]], ["content", "user.fullName", ["loc", [null, [1, 299], [1, 316]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 331], [1, 339]]]]], [], 2, 3, ["loc", [null, [1, 325], [1, 767]]]], ["block", "unless", [["get", "expanded", ["loc", [null, [1, 782], [1, 790]]]]], [], 4, null, ["loc", [null, [1, 772], [1, 872]]]], ["inline", "t", ["notification.activity"], [], ["loc", [null, [1, 876], [1, 905]]]], ["inline", "format-date", [["get", "digest.updatedAt", ["loc", [null, [1, 920], [1, 936]]]], "MMM D | h:mm A"], [], ["loc", [null, [1, 906], [1, 955]]]], ["element", "action", ["toggleExpanded"], [], ["loc", [null, [1, 990], [1, 1017]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 1048], [1, 1056]]]]], [], 5, 6, ["loc", [null, [1, 1042], [1, 1151]]]], ["block", "modal-context", [], [], 7, null, ["loc", [null, [1, 1175], [1, 1445]]]], ["attribute", "class", ["subexpr", "if", [["get", "expanded", ["loc", [null, [1, 1479], [1, 1487]]]], "", "u-hidden"], [], ["loc", [null, [1, 1474], [1, 1503]]]]], ["block", "date-group", [], ["records", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [1, 1526], [1, 1539]]]]], [], []]], 8, null, ["loc", [null, [1, 1504], [1, 1639]]]], ["block", "unless", [["get", "notifications.inProgress", ["loc", [null, [1, 1649], [1, 1673]]]]], [], 9, null, ["loc", [null, [1, 1639], [1, 1875]]]], ["block", "if", [["get", "expanded", ["loc", [null, [1, 1881], [1, 1889]]]]], [], 10, null, ["loc", [null, [1, 1875], [1, 1940]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1953
          }
        },
        "moduleName": "milegacy/templates/components/user-group.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "expanded", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, null, ["loc", [null, [1, 0], [1, 51]]]], ["block", "if", [["get", "hasFriendRequest", ["loc", [null, [1, 57], [1, 73]]]]], [], 1, 2, ["loc", [null, [1, 51], [1, 1953]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});