define('milegacy/initializers/fastclick', ['exports'], function (exports) {
  exports.initialize = initialize;
  /* global FastClick */

  function initialize() {
    // FastClick.attach(document.body);
  }

  exports['default'] = {
    name: 'fastclick',
    initialize: initialize
  };
});