define("milegacy/templates/wp/login", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 415
            },
            "end": {
              "line": 1,
              "column": 478
            }
          },
          "moduleName": "milegacy/templates/wp/login.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["signup.link"], [], ["loc", [null, [1, 459], [1, 478]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1049
            },
            "end": {
              "line": 1,
              "column": 1137
            }
          },
          "moduleName": "milegacy/templates/wp/login.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["login.forgot-password"], [], ["loc", [null, [1, 1108], [1, 1137]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1189
          }
        },
        "moduleName": "milegacy/templates/wp/login.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "login columns small-centered small-12 medium-8 large-6 xlarge-5");
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "t-tagline u-align-center banner");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "class", "fieldset");
        var el3 = dom.createElement("fieldset");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-6");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "t-btn-borderless");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-6 u-align-right");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-12");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "type", "submit");
        dom.setAttribute(el6, "class", "field button submit");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "columns small-offset-6 small-6 u-align-right");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [0]);
        var element3 = dom.childAt(element2, [0]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createAttrMorph(element2, 'disabled');
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [0, 0]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [2, 0]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [3, 0, 0]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [4, 0]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["login.banner"], [], ["loc", [null, [1, 124], [1, 144]]]], ["element", "action", ["authenticate", ["get", "model", ["loc", [null, [1, 178], [1, 183]]]]], ["on", "submit"], ["loc", [null, [1, 154], [1, 197]]]], ["attribute", "disabled", ["get", "model.operation.isPending", ["loc", [null, [1, 236], [1, 261]]]]], ["inline", "t", ["login.link"], [], ["loc", [null, [1, 341], [1, 359]]]], ["block", "link-to", ["users.new"], ["class", "button-link"], 0, null, ["loc", [null, [1, 415], [1, 490]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 568], [1, 573]]]]], [], []], "for", "email", "value", ["subexpr", "@mut", [["get", "model.email", ["loc", [null, [1, 592], [1, 603]]]]], [], []], "type", "email", "placeholder", "Email Address", "error", ["subexpr", "@mut", [["get", "model.error", ["loc", [null, [1, 651], [1, 662]]]]], [], []]], ["loc", [null, [1, 549], [1, 664]]]], ["inline", "text-field", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 742], [1, 747]]]]], [], []], "for", "password", "value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [1, 769], [1, 783]]]]], [], []], "type", "password", "placeholder", "Password"], ["loc", [null, [1, 723], [1, 824]]]], ["inline", "t", ["login.submit"], [], ["loc", [null, [1, 933], [1, 953]]]], ["block", "link-to", ["account.password.request"], ["class", "button-link"], 1, null, ["loc", [null, [1, 1049], [1, 1149]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});