define('milegacy/components/user-widget', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':user', ':cell'],
    relationshipName: (function () {
      var friendship = this.get('friendship');

      if (_ember['default'].isPresent(friendship.get('relationshipId'))) {
        return friendship.get('relationship.name');
      } else {
        if (friendship.get('pending')) {
          return 'Pending Friend';
        } else {
          return 'Friend';
        }
      }
    }).property('relationship'),
    actions: {
      swallow: function swallow() {}
    }
  });
});