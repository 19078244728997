define('milegacy/controllers/login', ['exports', 'ember', 'npm:moment', 'milegacy/config/environment'], function (exports, _ember, _npmMoment, _milegacyConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({

    credentials: _ember['default'].inject.service('credentials'),
    user: '',
    errors: '',
    errorMsg: 'error message',
    showAgeError: true,
    createUserError: '',
    wp_links: _milegacyConfigEnvironment['default'].wpLinks,

    datePickerOptions: {
      // since the formats have been changed, we need to make an adjustment of 1 day.
      max: (0, _npmMoment['default'])().subtract(_npmMoment['default'].duration(13, 'year')).subtract(1, 'day').toDate(),
      selectYears: 100,
      selectMonths: true,
      format: 'mm-dd-yyyy',
      formatSubmit: 'yyyy-mm-dd'
    },

    actions: {

      createAccount: function createAccount(model) {
        var _this = this;

        if (this.validUserDetail(model)) {
          var credentials = this.get('credentials').getProperties('email', 'password');
          this.set('user', this.store.createRecord('user', credentials));
          this.get('user').set('fullName', model.get('firstName') + ' ' + model.get('lastName'));
          this.get('user').set('email', model.get('email'));
          this.get('user').set('password', model.get('password'));
          this.get('user').set('gender', model.get('gender'));
          this.get('user').set('birthday', model.get('dob'));
          this.get('user').set('acceptance_flag', true);
          return this.get('user').save().then(function () {
            return _this.transitionToRoute('users.success');
          }, function (model) {
            _this.set('errors', model.errors);
            var error = '';
            for (var i = 0; i < _this.get('errors').length; i++) {
              var title = _this.get('errors')[i].source.pointer;
              var titleArray = title.split('/');
              error += _this.jsUcfirst(titleArray[titleArray.length - 1]) + ' ' + _this.get('errors')[i].title + '\n';
            }
            _this.set('errorMsg', error);
            _this.set('createUserError', error);
            document.getElementsByClassName('creat-acc-error-msg')[0].style.display = "block";
            setTimeout(function () {
              document.getElementsByClassName('creat-acc-error-msg')[0].style.display = "none";
            }, 7000);
          });
        } else {
          this.showAgeError = true;
        }
      }
    },

    enableCreateAccount: (function () {
      if (this.get('model.dob') && _npmMoment['default'].duration((0, _npmMoment['default'])() - (0, _npmMoment['default'])(this.get('model.dob'))).years() < 13) {
        this.set('model.dob', '');
        _ember['default'].$('.dob-msg').click();
      }
      return this.validUserDetail(this.model);
    }).property('model', 'model.firstName', 'model.email', 'model.gender', 'model.dob', 'model.password'),

    validUserDetail: function validUserDetail(model) {
      if (model.get('email') && model.get('password') && model.get('dob') !== null) {
        return model.get('firstName').trim().length > 0 && model.get('email').trim().length > 0 && model.get('password').trim().length > 0 && model.get('dob').toString().trim().length > 0 && model.get('gender').trim().length > 0;
      } else {
        return false;
      }
    },

    jsUcfirst: function jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  });
});