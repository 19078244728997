define('milegacy/components/relationship-popup', ['exports', 'ember', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    currentUser: _ember['default'].computed.alias('session.currentUser'),
    options: [1],
    selectedRelationship: "",

    relationshipCollection: (function () {
      var store = this.get('store');
      return store.peekAll('relationship').sortBy('name');
    }).property('relationshipCollection'),

    relationshipSelected: (function () {
      return !this.get('selectedRelationship').length;
    }).property('selectedRelationship', 'relationshipSelected'),

    actions: {
      setSelectedRelationship: function setSelectedRelationship(newValue) {
        this.set('selectedRelationship', newValue.id);
      },

      add_friend: function add_friend() {
        var _this = this;

        var store = this.get('store');
        var user = this.get('currentUser');
        var friendship = (0, _milegacyUtilsUnwrap.unwrap)(this.get('friendship'));
        var unloadUser = this.get('unloadUser');
        var relationship_id = this.get('selectedRelationship');

        if (relationship_id) {
          // "pending": true,"user_id": "118","friend_id": "114","relationship_id": "2"
          store.findRecord('user', friendship.get('id')).then(function (friend) {
            var newFriendship = store.createRecord('friendship', {
              user: user,
              friend: friend,
              relationshipId: relationship_id,
              pending: true
            });
            newFriendship.save().then(function () {
              // Remove the user record from the listing.
              if (unloadUser) {
                store.unloadRecord(friendship);
              }

              _this.sendAction('refresh', { friendship: newFriendship });
            });
          });
        }
      }
    }
  });
});