define('milegacy/models/notification-digest', ['exports', 'ember-data', 'milegacy/models/digest'], function (exports, _emberData, _milegacyModelsDigest) {
  exports['default'] = _milegacyModelsDigest['default'].extend({
    updatedAt: _emberData['default'].attr('date'),
    count: _emberData['default'].attr('number'),
    readAt: _emberData['default'].attr('date'),
    read: _emberData['default'].attr('boolean'),
    timecapsuleCount: _emberData['default'].attr('number'),
    parent: _emberData['default'].belongsTo('notification-digest', { inverse: 'subdigests' }),
    subdigests: _emberData['default'].hasMany('notification-digest', { inverse: 'parent' })
  });
});