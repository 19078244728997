define('milegacy/components/media-image', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    displayUrl: (function () {
      var media = this.get('media');
      return media.get('displayUrl');
    }).property('media', 'media.displayUrl'),
    defaultImage: _ember['default'].computed('media.displayKind', 'small', function () {
      if (this.get('media.displayKind') === 'video') {
        if (this.get('small')) {
          return 'assets/images/small-video-play-icon.svg';
        } else {
          return 'assets/images/video-play-icon.svg';
        }
      }
    })
  });
});