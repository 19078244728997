define('milegacy/mixins/story-uploader', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      uploadStory: function uploadStory(persistor) {
        var _this = this;

        var promise = persistor.save();

        promise.then(function () {
          _this.set('controller.model.newStory', _this.newStory());
          setTimeout(function () {
            _this.send('refresh');
          }, 1000);
          persistor.saveMedia();
        });

        return true;
      }
    }
  });
});