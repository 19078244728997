define('milegacy/routes/timecapsules', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    'page:title': 'MiNews',
    'page:banner': 'MiNews',
    'page:layout': 'app',
    'page:menu': _ember['default'].computed.alias('menu'),
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'All News',
        icon: 'all',
        iconColor: 'gray'
      }));

      return _ember['default'].Object.create({
        prompt: _ember['default'].Object.create({
          link: 'notifications.index',
          name: 'All News'
        })
      });
    }
  });
});