define('milegacy/utils/rollbackRelationships', ['exports', 'npm:lodash/collection/includes'], function (exports, _npmLodashCollectionIncludes) {
  exports.rollbackBelongsTo = rollbackBelongsTo;
  exports.rollbackHasMany = rollbackHasMany;
  exports.rollbackRelationships = rollbackRelationships;

  function rollbackBelongsTo(model, name) {
    var rel = model._internalModel._relationships.get(name);
    var original = rel.canonicalState;
    rel.setRecord(original);
  }

  function rollbackHasMany(model, name) {
    var recordProxy = model.get(name + '.content');
    var rel = recordProxy.relationship;
    var key = rel.inverseKey;
    var original = rel.canonicalState.toArray();
    var current = recordProxy.toArray();

    original.forEach(function (item) {
      rollbackBelongsTo(item.record, key);
      item.record.rollbackAttributes();
    });

    current.forEach(function (record) {
      record.rollbackAttributes();
    });
  }

  function rollbackRelationships(model, relationshipsToRollback) {
    model.eachRelationship(function (name, relationship) {
      if (!(0, _npmLodashCollectionIncludes['default'])(relationshipsToRollback, name)) {
        return;
      }
      switch (relationship.kind) {
        case 'belongsTo':
          rollbackBelongsTo(model, name);
          break;
        case 'hasMany':
          rollbackHasMany(model, name);
          break;
        default:
          return;
      }
    });
  }
});