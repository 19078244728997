define("milegacy/templates/components/search-results", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 242
                  },
                  "end": {
                    "line": 1,
                    "column": 287
                  }
                },
                "moduleName": "milegacy/templates/components/search-results.hbs"
              },
              isEmpty: true,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 121
                },
                "end": {
                  "line": 1,
                  "column": 303
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "user-widget", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [1, 262], [1, 266]]]]], [], []], "class", "user-small"], 0, null, ["loc", [null, [1, 242], [1, 303]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 57
              },
              "end": {
                "line": 1,
                "column": 321
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "milestones", "models", ["subexpr", "@mut", [["get", "user.id", ["loc", [null, [1, 163], [1, 170]]]]], [], []], "result", ["subexpr", "@mut", [["get", "user", ["loc", [null, [1, 178], [1, 182]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 191], [1, 204]]]]], [], []], "clearSearchField", "clearSearchField"], 0, null, ["loc", [null, [1, 121], [1, 321]]]]],
          locals: ["user"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 421
                },
                "end": {
                  "line": 1,
                  "column": 772
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "titles");
              var el3 = dom.createElement("h2");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "actions");
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [0]);
              var element4 = dom.childAt(element3, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [0, 0]), 0, 0);
              morphs[1] = dom.createAttrMorph(element4, 'class');
              return morphs;
            },
            statements: [["content", "story.title", ["loc", [null, [1, 619], [1, 634]]]], ["attribute", "class", ["concat", ["icon icon-outline icon-", ["get", "story.milestone.color", ["loc", [null, [1, 701], [1, 722]]]], " icon-", ["get", "story.milestone.slug", ["loc", [null, [1, 732], [1, 752]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 346
              },
              "end": {
                "line": 1,
                "column": 790
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "story", "models", ["subexpr", "list", [["get", "story.userId", ["loc", [null, [1, 464], [1, 476]]]], ["get", "story.milestoneId", ["loc", [null, [1, 477], [1, 494]]]], ["get", "story.id", ["loc", [null, [1, 495], [1, 503]]]]], [], ["loc", [null, [1, 458], [1, 504]]]], "result", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 512], [1, 517]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 526], [1, 539]]]]], [], []], "clearSearchField", "clearSearchField"], 0, null, ["loc", [null, [1, 421], [1, 790]]]]],
          locals: ["story"],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 899
                },
                "end": {
                  "line": 1,
                  "column": 1275
                }
              },
              "moduleName": "milegacy/templates/components/search-results.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "titles");
              var el3 = dom.createElement("h2");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h3");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "actions");
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [0]);
              var element1 = dom.childAt(element0, [0]);
              var element2 = dom.childAt(element0, [1, 0]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              morphs[2] = dom.createAttrMorph(element2, 'class');
              return morphs;
            },
            statements: [["content", "story.title", ["loc", [null, [1, 1097], [1, 1112]]]], ["content", "story.author", ["loc", [null, [1, 1121], [1, 1137]]]], ["attribute", "class", ["concat", ["icon icon-outline icon-", ["get", "story.milestone.color", ["loc", [null, [1, 1204], [1, 1225]]]], " icon-", ["get", "story.milestone.slug", ["loc", [null, [1, 1235], [1, 1255]]]]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 815
              },
              "end": {
                "line": 1,
                "column": 1293
              }
            },
            "moduleName": "milegacy/templates/components/search-results.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "search-result", [], ["link", "story", "models", ["subexpr", "list", [["get", "story.userId", ["loc", [null, [1, 942], [1, 954]]]], ["get", "story.milestoneId", ["loc", [null, [1, 955], [1, 972]]]], ["get", "story.id", ["loc", [null, [1, 973], [1, 981]]]]], [], ["loc", [null, [1, 936], [1, 982]]]], "result", ["subexpr", "@mut", [["get", "story", ["loc", [null, [1, 990], [1, 995]]]]], [], []], "current", ["subexpr", "@mut", [["get", "currentResult", ["loc", [null, [1, 1004], [1, 1017]]]]], [], []], "clearSearchField", "clearSearchField"], 0, null, ["loc", [null, [1, 899], [1, 1293]]]]],
          locals: ["story"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 42
            },
            "end": {
              "line": 1,
              "column": 1318
            }
          },
          "moduleName": "milegacy/templates/components/search-results.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "search-results-group", [], ["title", "People", "results", ["subexpr", "@mut", [["get", "users", ["loc", [null, [1, 104], [1, 109]]]]], [], []]], 0, null, ["loc", [null, [1, 57], [1, 346]]]], ["block", "search-results-group", [], ["title", "Your Stories", "results", ["subexpr", "@mut", [["get", "myStories", ["loc", [null, [1, 399], [1, 408]]]]], [], []]], 1, null, ["loc", [null, [1, 346], [1, 815]]]], ["block", "search-results-group", [], ["title", "Friends’ Stories", "results", ["subexpr", "@mut", [["get", "friendsStories", ["loc", [null, [1, 872], [1, 886]]]]], [], []]], 2, null, ["loc", [null, [1, 815], [1, 1318]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 1331
          }
        },
        "moduleName": "milegacy/templates/components/search-results.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "search-results-list");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element5, 0, 0);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["content", "yield", ["loc", [null, [1, 33], [1, 42]]]], ["block", "if", [["get", "results", ["loc", [null, [1, 48], [1, 55]]]]], [], 0, null, ["loc", [null, [1, 42], [1, 1325]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});