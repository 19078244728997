define('milegacy/models/friendship', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    pending: _emberData['default'].attr('boolean'),
    user: _emberData['default'].belongsTo('user'),
    userId: _ember['default'].computed.readOnly('user.id'),
    friend: _emberData['default'].belongsTo('user'),
    friendId: _ember['default'].computed.readOnly('friend.id'),
    relationship: _emberData['default'].belongsTo('relationship'),
    relationshipId: _emberData['default'].attr('string'),
    friendName: _ember['default'].computed.readOnly('friend.fullName'),
    requesterId: _emberData['default'].attr()
  });
});