define('milegacy/components/comment-widget', ['exports', 'ember', 'milegacy/utils/unwrap'], function (exports, _ember, _milegacyUtilsUnwrap) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNameBindings: [':comment'],
    session: _ember['default'].inject.service('session'),

    isCurrentUserComment: (function () {
      var currentUser = this.get('session.currentUser');
      var userId = currentUser.get('id');
      return userId == this.get('model.userId');
    }).property('model'),

    actions: {
      remove: function remove() {
        this.get('story.comments').removeObject(this.get('model'));
        (0, _milegacyUtilsUnwrap.unwrap)(this.get('model')).destroyRecord();
      }
    }
  });
});