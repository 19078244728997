define('milegacy/components/friend-actions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':friend-list', ':list'],
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    currentUser: _ember['default'].computed.alias('session.currentUser'),
    friendshipSentByUser: (function () {
      var currentUserId = this.get('currentUser.id');
      var friendship = this.get('friendship');
      return this.get('pendingRequest') && friendship.get('requesterId') != Number.parseInt(currentUserId);
    }).property('friendshipSentByUser'),
    pendingRequest: (function () {
      var friendship = this.get('friendship');
      return friendship.get('pending');
    }).property('pendingRequest'),
    relationshipName: (function () {
      if (_ember['default'].isPresent(this.get('friendship.relationship.name'))) {
        return this.get('friendship.relationship.name');
      } else {
        return 'friend';
      }
    }).property('relationshipName'),
    actions: {
      unfriend: function unfriend(friendship) {
        var _this = this;

        var store = this.get('store');
        friendship.destroyRecord().then(function () {
          store.unloadRecord(friendship);
          _this.sendAction('refresh');
        });
      },
      accept: function accept(friendship) {
        var _this2 = this;

        var store = this.get('store');
        friendship.set('pending', false);
        friendship.save().then(function () {
          store.unloadRecord(friendship);
          _this2.sendAction('refresh');
        });
      }
    }
  });
});