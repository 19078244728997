define('milegacy/services/devise', ['exports', 'ember', 'milegacy/config/environment'], function (exports, _ember, _milegacyConfigEnvironment) {

  var deviseConfig = _milegacyConfigEnvironment['default'].devise;

  exports['default'] = _ember['default'].Service.extend({
    host: deviseConfig.host,
    routes: deviseConfig.routes,
    confirm: function confirm(token) {
      var deferred = _ember['default'].$.getJSON(this.host + this.routes.confirm, { confirmation_token: token });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    sendConfirmationEmail: function sendConfirmationEmail(email) {
      var deferred = _ember['default'].$.post(this.host + this.routes.sendConfirmationEmail, { user: { email: email } });
      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    requestPasswordReset: function requestPasswordReset(email) {
      var deferred = _ember['default'].$.post(this.host + this.routes.passwordReset, { user: { email: email } });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    resetPassword: function resetPassword(password, password_confirmation, token) {
      var deferred = _ember['default'].$.ajax(this.host + this.routes.passwordReset, {
        type: 'PUT',
        data: { user: {
            password: password,
            password_confirmation: password_confirmation,
            reset_password_token: token
          }
        }
      });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    },
    sendLogoutRequest: function sendLogoutRequest(request_token, user_id) {
      var deferred = _ember['default'].$.ajax(this.host + this.routes.signOut, {
        type: 'DELETE',
        data: {},
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader("Authorization", "Token token=" + request_token + ", id=" + user_id);
        }
      });

      return _ember['default'].RSVP.Promise.resolve(deferred);
    }
  });
});