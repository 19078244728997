define('milegacy/models/media', ['exports', 'ember', 'ember-data', 'milegacy/models/commentable', 'milegacy/utils/memoize'], function (exports, _ember, _emberData, _milegacyModelsCommentable, _milegacyUtilsMemoize) {
  exports['default'] = _milegacyModelsCommentable['default'].extend({
    file: null,
    url: _emberData['default'].attr('string'),
    kind: _emberData['default'].attr('string'),
    // Memoize to prevent flickering,
    // until AWS URLs are cache friendly.
    // https://github.com/aws/aws-sdk-ruby/issues/1152
    signedUrls: (0, _milegacyUtilsMemoize['default'])(_emberData['default'].attr('hash', { readOnly: true })),
    displayKind: (function () {
      var kind = this.get('file.kind');

      if (_ember['default'].isPresent(kind)) {
        return kind;
      } else {
        return this.get('kind');
      }
    }).property('file.kind', 'kind'),
    originalUrl: (function () {
      var url = this.get('file.displayUrl');

      if (_ember['default'].isPresent(url)) {
        return url;
      }

      switch (this.get('kind')) {
        case 'image':
          return this.get('signedUrls.original');
        case 'video':
          return this.get('signedUrls.thumb');
      }
    }).property('file.displayUrl', 'kind', 'signedUrls'),
    displayUrl: (function () {
      var url = this.get('file.displayUrl');

      if (_ember['default'].isPresent(url)) {
        return url;
      }

      switch (this.get('kind')) {
        case 'image':
          var desktop_thumb = this.get('signedUrls.desktop_thumb');

          if (_ember['default'].isPresent(desktop_thumb)) {
            return desktop_thumb;
          } else {
            return this.get('signedUrls.original');
          }
        case 'video':
          return this.get('signedUrls.thumb');
      }
    }).property('file.displayUrl', 'kind', 'signedUrls'),
    mobileUrl: (function () {
      switch (this.get('kind')) {
        case 'image':
          return this.get('signedUrls.mobile_thumb');
        case 'video':
          return this.get('signedUrls.thumb');
      }
    }).property('kind', 'signedUrls'),
    status: _emberData['default'].attr('string'),
    mimetype: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    story: _emberData['default'].belongsTo('story'),
    isUploaded: (function () {
      return this.get('status') === 'uploaded';
    }).property('status')
  });
});