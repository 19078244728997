define('milegacy/components/side-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'nav',
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    classNameBindings: [':sidebar'],

    sidebarItems: (function () {
      var _this = this;

      var store = this.get('store');
      var count = 0;
      this.get('items').filterBy('link', 'milestones.index').forEach(function (milestone) {
        count = count + milestone.get('badge');
      });
      return this.get('items').forEach(function (item) {
        var timecapsules = store.peekRecord('timecapsule-digest', 'milestone-');
        if (timecapsules) {
          count = count + timecapsules.get('count');
        }
        if (!item.get('id') && _this.get('requestForCurrentUser') && item.link.indexOf('milestone') > -1 && _this.get('totalStoryCount') > 0) {
          item.badge = count;
          _this.set('totalStoryCount', count);
        }
      });
    }).property('items.[]'),

    actions: {
      refreshTimeCapsuleCount: function refreshTimeCapsuleCount() {
        var store = this.get('store');
        var count = 0;
        var link = this.get('link');
        this.get('sidebarItems').filterBy('link', 'milestones.index').forEach(function (milestone) {
          count = count + milestone.get('badge');
        });
        var timecapsules = store.peekRecord('timecapsule-digest', 'milestone-');
        if (timecapsules) {
          count = count + timecapsules.get('count');
        }
        if (timecapsules && link == 'milestone' && this.get('requestForCurrentUser')) {
          this.set('timeCapsuleCount', timecapsules.get('count'));
        } else {
          this.set('timeCapsuleCount', null);
        }
        this.set('totalStoryCount', count);
      }
    }

  });
});