define('milegacy/components/upload-manager', ['exports', 'ember'], function (exports, _ember) {

  var $window = _ember['default'].$(window);

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':upload-manager', 'isEmpty:empty'],
    isEmpty: _ember['default'].computed.empty('medias'),
    actions: {
      removeMedia: function removeMedia(media) {
        this.get('medias').removeObject(media);

        // Since images in the horizontal scroller to the right of the removed media will now be shifted into view, trigger lazyLoading of those images.
        $window.trigger('scrollLazy');
      }
    }
  });
});