define('milegacy/routes/friends', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'MiFriends',
    'page:banner': 'MiFriends',
    'page:layout': 'app',
    'page:menu': _ember['default'].computed.alias('menu'),
    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'All',
        icon: 'all',
        iconColor: 'gray'
      }));

      return _ember['default'].Object.create({
        prompt: _ember['default'].Object.create({
          link: 'friends.index',
          name: 'All',
          icon: 'all',
          iconColor: 'gray'
        })
      });
    }
  });
});