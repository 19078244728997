define('milegacy/utils/hasDirtyRelationships', ['exports', 'npm:lodash/lang/isEqual', 'npm:lodash/collection/includes'], function (exports, _npmLodashLangIsEqual, _npmLodashCollectionIncludes) {
  exports.hasDirtyRelationships = hasDirtyRelationships;

  function hasDirtyRelationships(model, relationshipsToCheck) {
    var isDirty = false;
    model.eachRelationship(function (name, relationship) {
      if (!(0, _npmLodashCollectionIncludes['default'])(relationshipsToCheck, name)) {
        return;
      }
      var rel, original, current;
      switch (relationship.kind) {
        case 'belongsTo':
          rel = model._internalModel._relationships.get(name);
          original = rel.canonicalState;
          current = model.get(name + '.content')._internalModel;
          if (original !== current) {
            isDirty = true;
          }
          break;
        case 'hasMany':
          rel = model.get(name + '.content');
          original = rel.canonicalState;
          current = rel.currentState;
          if (!(0, _npmLodashLangIsEqual['default'])(current, original)) {
            isDirty = true;
          }
          break;
        default:
          return;
      }
    });
    return isDirty;
  }
});