define("milegacy/templates/components/search-widget", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 330
                },
                "end": {
                  "line": 1,
                  "column": 391
                }
              },
              "moduleName": "milegacy/templates/components/search-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "u-align-center");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["content", "x-loader", ["loc", [null, [1, 373], [1, 385]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.2.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 1,
                      "column": 412
                    },
                    "end": {
                      "line": 1,
                      "column": 457
                    }
                  },
                  "moduleName": "milegacy/templates/components/search-widget.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("No results found for this…");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 399
                  },
                  "end": {
                    "line": 1,
                    "column": 468
                  }
                },
                "moduleName": "milegacy/templates/components/search-widget.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "results", ["loc", [null, [1, 422], [1, 429]]]]], [], 0, null, ["loc", [null, [1, 412], [1, 468]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.2.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 1,
                    "column": 468
                  },
                  "end": {
                    "line": 1,
                    "column": 488
                  }
                },
                "moduleName": "milegacy/templates/components/search-widget.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Keep typing…");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.2.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 1,
                  "column": 391
                },
                "end": {
                  "line": 1,
                  "column": 495
                }
              },
              "moduleName": "milegacy/templates/components/search-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "valid", ["loc", [null, [1, 405], [1, 410]]]]], [], 0, 1, ["loc", [null, [1, 399], [1, 495]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.1",
            "loc": {
              "source": null,
              "start": {
                "line": 1,
                "column": 233
              },
              "end": {
                "line": 1,
                "column": 507
              }
            },
            "moduleName": "milegacy/templates/components/search-widget.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1, "class", "search-prompt");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "loading", ["loc", [null, [1, 336], [1, 343]]]]], [], 0, 1, ["loc", [null, [1, 330], [1, 502]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 214
            },
            "end": {
              "line": 1,
              "column": 526
            }
          },
          "moduleName": "milegacy/templates/components/search-widget.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "search-results", [], ["results", ["subexpr", "@mut", [["get", "results", ["loc", [null, [1, 259], [1, 266]]]]], [], []], "clearSearchField", "clearSearchField"], 0, null, ["loc", [null, [1, 233], [1, 526]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.2.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 533
          }
        },
        "moduleName": "milegacy/templates/components/search-widget.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("i");
        dom.setAttribute(el1, "class", "icon icon-input icon-search");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [1, 69], [1, 74]]]]], [], []], "placeholder", "Search for people or stories.", "focus-in", "focus", "escape-press", "cancel", "enter", "jump", "class", "input-dark with-icon search-input"], ["loc", [null, [1, 43], [1, 214]]]], ["block", "if", [["get", "showResults", ["loc", [null, [1, 220], [1, 231]]]]], [], 0, null, ["loc", [null, [1, 214], [1, 533]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});